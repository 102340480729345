
import DateFunction from "../utils/DateFunctions";


export const generateInvoiceHtmlForPDF = (item, loggeduser) => {
    const { facilityLogoBase64, facility_name } = loggeduser;
    const mapLineItems = item.invoices
    .map((lineItem, index) => {
      return `
        <tr>
            <td style='text-align:center;'>${index + 1}</td>
            <td style='text-align:left;'>${lineItem.description}</td>

            <td style='text-align:center;'>${lineItem.quantity}</td>
            <td style='text-align:center;'>₹ ${lineItem.amount.toFixed(2)}</td>
            <td style='text-align:center;'> ${lineItem.gst}</td>
            <td style='text-align:center;'>₹ ${lineItem.total.toFixed(2)}</td>
        </tr>`;
    })
    .join('');
  


  return `
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Invoice Header</title>
      <style>
      body {
        font-family: Arial, sans-serif;
        margin: 0;
        padding: 0;
        color: #333;
      }
  
      .invoice-header {
        max-width: 800px;
        margin: 20px auto;
        border: 1px solid #ddd;
        border-radius: 5px;
       
        background-color: #f9f9f9;
      }
  
      .header-top {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
  
      
      .logo img {
        max-width: 250px;
        max-height: 100px;
        object-fit: contain;
    }
      .header-top .invoice-details {
        font-size: 14px;
        padding-left: 20px;
        invoice-details
        text-align: right;
      }
  
      .header-top .invoice-details p {
        margin: 2px 0;
      }
  
      .header-bottom {
        text-align: center;
       margin-bottom: 20px;
        font-size: 14px;
        color: #aaa;
      }
  
      .divider {
        border-top: 8px dashed #ddd; /* Dashed line with specified width */
        margin: 15px 0;
        border-width: 8px;
        border-style: dashed;
        border-color: #ddd;
        border-image: repeating-linear-gradient(to right, #ddd 0, #ddd 10px, transparent 10px, transparent 20px);
        border-image-slice: 1;
        
      }
    
  
      .details-row {
        display: flex;
        padding-left: 40px;
        padding-right: 30px;
        justify-content: space-around;
        font-size: 14px;
        margin-bottom: 20px;
      }
  
      .details-row div {
        flex: 1;
      }
  
      .details-row div:first-child {
        margin-right: 10px;
        margin-left:25px
      }
  
      .details-row div:last-child {
        margin-left: 20px;
      }
  
      .details-row p {
        margin: 5px 0;
      }
  
      table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
    }
    table th, table td {
        padding: 8px;
        text-align: left;
         font-size: 0.9em;             
    }
    table th {
        background-color: #ddd;
        text-align:center;
       
    }
       
    
        .dotted-divider {
            border-top: 1px dashed #969696;
            margin: 15px 0;
        }
        .totals {
            width: 100%;
            margin-top: 5px;
        }
        .totals td {
            padding: 10px 8px;
        }
        .totals td:first-child {
            text-align: left;
        }
        .totals td:last-child {
            text-align: right;
        }
       
      </style>
    </head>
    <body>
    <div class="invoice-header">
    <!-- Top Section -->
    <div class="header-top">
        <div class="logo">
             ${
                facilityLogoBase64
               ? `<img src="${facilityLogoBase64}" style="width: 250px; height: 100px;" alt="Facility Logo" />`
               : `<div style="font-size: 1.5em; font-weight: bold;margin-top:25px"> ${facility_name}</div>`
           }
        </div>
        <div class="invoice-details">
            <p><strong>Invoice No.</strong>:  ${item.invoice_code}</p>
            <p><strong>Invoice Date</strong>: ${DateFunction.GetDate(
             item.invoice_on
               )}</p>
            <p><strong>Patient ID</strong>: ${
            item?.appointments?.patient_op_number
           }</p>
        </div>
    </div>

    <!-- Centered Text -->
    <div class="header-bottom">
      <span>INVOICE</span>
    </div>

    <!-- Divider -->
    <div style="border: 1px dashed #969696;"></div>
    <div class="details-container">
    <!-- Details Section -->
    <div class="details-row">
      <div>
        <p><span>Name:</span> ${
          item?.patients?.FirstName +
          " " +
          item?.patients?.MiddleName?.charAt(0) +
          " " +
          item?.patients?.LastName
        }</p>
        <p><span>Gender:</span> ${
          item?.patients?.gender ? item?.patients?.gender : "-"
        }</p>
        <p><span>Age:</span> ${
          item?.patients?.dob ? DateFunction.GetAge(item?.patients?.dob) : "-"
        }</p>
       
      </div>
      <div>
        <p><span>Email:</span> ${
          item?.patients?.email_id ? item?.patients?.email_id : "-"
        }</p>
        <p><span>Phone:</span> ${
          item?.patients?.mobile_number ? item?.patients?.mobile_number : "-"
        }</p>
        <p><span>Ref by:</span> ${
          item?.reffered_by ? item.reffered_by : "-"
        }</p>
      </div>
    </div>
    <div style="border: 1px dashed #969696; "></div>
    <!-- Table Section -->
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th  style='text-align:center;  margin-left: 20px;"'>#</th>
            <th  style='text-align:left;'>Description</th>
            
            <th style='text-align:center';>Qty</th>
            <th>Amount</th>
            <th>GST</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
        ${mapLineItems}
        </tbody>
      </table>
    </div>
    <div class="dotted-divider"></div>
    <div class="totals">
    <!-- Apply margins to this wrapper -->
    <div style="margin-left: 20px; margin-right: 20px;">
    <div style="font-size: 0.9em; margin: 10px 0; display: flex; justify-content: space-between;">
        <span style="flex: 1; text-align: left;">Sub Total</span>
        <span style="flex: 0 0 20px; text-align: center;">: ₹&nbsp;</span>
        <span style="flex: 0 0 60px; text-align: right;">${item.amount.toFixed(2)}</span>
    </div>
    <div style="font-size: 0.9em; margin: 10px 0; display: flex; justify-content: space-between; margin-bottom: 20px;">
        <span style="flex: 1; text-align: left;">GST</span>
        <span style="flex: 0 0 20px; text-align: center;">: ₹&nbsp;</span>
        <span style="flex: 0 0 60px; text-align: right;">${item.gst.toFixed(2)}</span>
    </div>
</div>
<div style="border: 1px dashed #969696;"></div>
    <!-- Apply margins to this wrapper -->
    <div style="margin-left: 20px; margin-right: 20px;">
        <div style="font-size: 0.9em; margin: 10px 0; display: flex; justify-content: space-between;">
            <span style="flex: 1; ">Amount due</span>
            <span style="flex: 0 0 20px; text-align: center;">: ₹&nbsp;</span>
            <span style="flex: 0 0 60px; text-align: right;">${item.totalAmount.toFixed(
              2
            )}</span>
        </div>
    </div>
    <div class="dotted-divider"></div>
    <div><br></div>

    <div style="text-align: right; padding-right: 20px; margin-bottom:20px">
    <div id="logo">
        <p class="right">
            Bill generated/ Printed by<br />
            <span id="userName"> (${loggeduser?.user_name})</span>
        </p>
    </div>
</div>
   </div>
  </div>
</div>


 
      
    </body>
    </html>
`;
  
};
