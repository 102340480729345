import React, { useRef, useEffect, useState } from "react";
import { CLINICAL_NOTES_TEMPLATE_OPTIONS } from "../../../../utils/Constant";
import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Skeleton,
} from "@mui/material";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Box, TextField, Typography, Paper } from "@mui/material";
import MicForTemplate from "../../../../components/Assets/recordIcon.png";
import store from "../../../../API/store";
import DataDecode from "../../../../utils/DataDecode";
import { setNotification } from "../../../../Redux/features/toast/toastSlice";
import {
  setIsTemplateview,
  setDefaultTemplateNames,
  setTemplateName,
} from "../../../../Redux/features/profileDrawer/profileSideNavbarSlice";
import BlueRondedCrossIcon from "../../../../components/Assets/BlueRondedCrossIcon.png";
import { theme } from "../../../../EmraxisTheme/Theme";

const TemplatesView = ({
  selectedClinicalNoteTemplate,
  setSelectedClinicalNoteTemplate,
  hanldeEnableRecordIcon,
}) => {
  //const [responseOfTemplate, setResponseOfTemplate] = useState([]);
  const intervalId = useRef(null);
  const dispatch = useDispatch();
  responseOfTemplate = [];
  const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
  var responseOfTemplate = useSelector(
    (state) => state.profileSideNavbar.defaultTemplateNames
  );
  const handleCloseTemplatesView = () => {
    dispatch(setIsTemplateview(false));
  };
  const handleRadioChange = async (event) => {
    setSelectedClinicalNoteTemplate(event);
    let TemplateName = event;
    dispatch(setTemplateName(TemplateName));
    let result = await store
      .GetClinicalNotesTemplates(DataDecode.encryptPayload(TemplateName))
      .then((response) => {
        if (response.status === 200) {
          response.json().then((res) => {
            dispatch(setDefaultTemplateNames(res));
          });
        } else {
          dispatch(
            setNotification({
              message: "Error occured while fetching template sections ",
              status: "error",
              action: true,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            message: "Error occured while fetching template sections ",
            status: "error",
            action: true,
          })
        );
      });
  };

  return (
    <>
      <div id="AddTemplateDivmainContainer" className="fixed inset-0 overflow-hidden flex justify-center items-center z-50 lg:p-8" >
        <div id="AddTemplateDivbackdrop" className="absolute inset-0 bg-black opacity-25" ></div>
        <div id="AddTemplateDivpopupContainer" className="relative bg-white shadow-xl p-2 lg:p-8 max-h-[100vh] md:max-h-[80vh] lg:max-h-[80vh] 2xl:max-h-[80vh] overflow-y-auto w-[365px] lg:w-[1000px] md:w-[700px]" >
          <div className="relative pb-20 hidden lg:block md:block">
            <div className={`absolute inset-x-0 top-0 text-center font-bold text-2xl ${previewTheme.headingTitleColor}`}
              style={{ fontFamily: previewTheme.fontFamily }}
            > Pick a template for the Clinical Note</div>
            <button id="AddTemplateBtncloseAddPopup" className={`absolute top-0 right-0 text-red-700`} onClick={handleCloseTemplatesView} >
              <svg id="AddTemplateSvgcloseAddPopupIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                className={`h-10 w-10 ${previewTheme.popupCloseBtnColor} transition-colors duration-300 ease-in-out hover:${previewTheme.popupCloseBtnHoverColor}`} >
                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            </button>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-[25%_75%] ">
            <div className="flex justify-center items-center w-full">
              <div className="hidden md:block">
                <RadioGroup
                  id="templateRadioGroupId"
                  aria-label="template"
                  name="template"
                  value={selectedClinicalNoteTemplate}
                  onChange={(e) => {
                    handleRadioChange(e.target.value);
                  }}
                >
                  {CLINICAL_NOTES_TEMPLATE_OPTIONS?.map((item) => (
                    <FormControlLabel
                      id="FormControlLabelId"
                      key={item.key}
                      value={item.id}
                      control={<Radio id="RadioId" sx={{
                        padding: "4px",
                        '&.Mui-checked': {
                          color: '#004F59',
                        }
                      }} />}
                      label={item.label}
                      labelPlacement="start"
                      sx={{
                        color: "#004F59",
                        marginBottom: 0.5,
                        margin: 0,
                      }}
                    />
                  ))}
                </RadioGroup>
              </div>
            </div>

            <div className="block md:hidden w-full">
              <div className="flex justify-end items-start">
                <button
                  id="AddTemplateBtncloseAddPopup"
                  className="text-red-700 md:hidden block"
                  onClick={handleCloseTemplatesView}
                >
                  <svg
                    id="AddTemplateSvgcloseAddmobileIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-10 w-10"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                </button>
              </div>

              <div onClick={hanldeEnableRecordIcon} className="text-center mb-4">
                <IconButton aria-label="mic_icon" id="mic_icon" size="small" className="rounded-full bg-gray-100 p-3">
                  <img
                    id="MicrophoneMobileId"
                    src={MicForTemplate}
                    alt="Microphone Icon"
                    className="h-10 w-10"
                  />
                </IconButton>
                <div className="font-['Inter'] font-semibold text-[12px] text-[#414141] mt-2">
                  Start Recording
                </div>
              </div>

              <div className={`font-['Inter'] font-semibold text-center px-4 py-2 text-[18px] mb-6 ${previewTheme.headingTitleColor}`}
                style={{ fontFamily: previewTheme.fontFamily }}>
                Pick a template for the Clinical Note
              </div>

              <div className="flex justify-center items-center mb-6">
                <RadioGroup
                  id="templateRadioGroupMobileId"
                  aria-label="template"
                  name="template"
                  value={selectedClinicalNoteTemplate}
                  onChange={(e) => {
                    handleRadioChange(e.target.value);
                  }}
                  className="flex flex-col space-y-2"
                >
                  {CLINICAL_NOTES_TEMPLATE_OPTIONS?.map((item) => (
                    <FormControlLabel
                      id="FormControlLabelMobileId"
                      key={item.key}
                      value={item.id}
                      control={
                        <Radio
                          id="RadioId"
                          sx={{
                            padding: "4px",
                            '&.Mui-checked': {
                              color: '#004F59',
                            }
                          }}
                        />
                      }
                      label={item.label}
                      sx={{
                        margin: 0,
                        '& .MuiTypography-root': {
                          fontSize: '14px',
                          color: "#004F59",
                        }
                      }}
                    />
                  ))}
                </RadioGroup>
              </div>

              <Paper
                id="PaperLablesMobileId"
                elevation={0}
                sx={{
                  padding: '1rem',
                  width: '80%',
                  margin: '0 auto',
                  border: '1px solid #e5e7eb',
                  borderRadius: '8px',
                  borderColor: 'black',
                  backgroundColor: '#ffffff'
                }}
              >
                {responseOfTemplate?.map((label, index) => (
                  <div key={index} className="mb-2">
                    <Box
                      id={`BoxMobileId${index}`}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px"
                      }}
                    >
                      <div className="flex items-center">
                        <Typography
                          id={`TypographymobileId${index}`}
                          variant="subtitle1"
                          sx={{
                            fontWeight: 550,
                            fontSize: '11px',
                            color: '#383434',
                            minWidth: 'fit-content',
                            marginRight: '2px'
                          }}
                        >
                          {typeof label === "string" ? label.toUpperCase() : "N/A"}:
                        </Typography>
                        <Skeleton
                          id={`SkeletonMobileId1-${index}`}
                          variant="text"
                          sx={{
                            flexGrow: 1,
                            height: 14,
                            borderRadius: '4px',
                            backgroundColor: '#f3f4f6'
                          }}
                        />
                      </div>
                      <Skeleton
                        id={`SkeletonMobileId2-${index}`}
                        variant="text"
                        sx={{
                          width: '100%',
                          height: 14,
                          borderRadius: '4px',
                          backgroundColor: '#f3f4f6'
                        }}
                      />
                    </Box>
                  </div>
                ))}
              </Paper>
            </div>

            <div className="bg-[#EAEAEA] p-4 rounded-lg md:block hidden">
              <Paper
                id="PaperLablesId"
                elevation={3}
                sx={{
                  padding: 3,
                  maxWidth: "100%",
                  margin: "auto",
                  overflowY: "auto",
                  height: "auto",
                  maxHeight: 260,
                }}
              >
                {responseOfTemplate?.map((label, index) => (
                  <div key={index} className="mb-4">
                    <Box
                      id={`BoxId${index}`}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        id={`TypographyId${index}`}
                        variant="subtitle1"
                        sx={{
                          fontWeight: "bold",
                          flexShrink: 0,
                        }}
                        className="text-[#383434] text-[14px] font-semibold"
                      >
                        {typeof label === "string" ? label.toUpperCase() : "N/A"}:
                      </Typography>
                      <Skeleton
                        id="SkeletonId"
                        variant="rectangular"
                        sx={{
                          flexGrow: 1,
                          height: 14,
                          borderRadius: 1,
                          ml: 2,
                        }}
                      />
                    </Box>
                    <Skeleton
                      id="SkeletonrectangularID"
                      variant="rectangular"
                      sx={{
                        height: 14,
                        borderRadius: 1,
                        mt: 1,
                      }}
                    />
                  </div>
                ))}
              </Paper>

              <div onClick={hanldeEnableRecordIcon} className="hidden md:block text-center mt-4">
                <IconButton aria-label="mic_icon" id="mic_icon" size="small">
                  <img
                    id="MicrophoneId"
                    src={MicForTemplate}
                    alt="Microphone Icon"
                    className="h-10 w-10"
                  />
                </IconButton>
                <div className="font-['Inter'] font-semibold text-[12px] text-[#414141] mt-1">
                  Start Recording
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>

  );
};
export default TemplatesView;