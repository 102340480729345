import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../../../../Redux/features/toast/toastSlice";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import store from "../../../../API/store";
import DataDecode from "../../../../utils/DataDecode";
import EMRAudioRecorder from "../Audio/EMRAudioRecorder";
import AudioFiles from "../../../../components/Assets/files.png";
import { ReactMic } from "react-mic";
import {
  setShowRecoringPopup,
  setStartRecording,
  setRecConfirmBox,
  setOpenRecordingPopUp
} from "../../../../Redux/features/recording/recordingSlice";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import EMRAudioPlayer from "../Audio/EMRAudioPlayer";
import {
  setDocumentType,
  setIsCameraUpload,
  setRefreshNotes,
  setShowUploadPopup,
} from "../../../../Redux/features/uploadDoc/uploadDocSlice";
import FileIcon from "../FileIcon";
import DateFunction from "../../../../utils/DateFunctions";
import {
  setIsGlobalSaveRequired,
  setNewAppointmentId,
  setIsTemplateview
} from "../../../../Redux/features/profileDrawer/profileSideNavbarSlice";
import { theme } from "../../../../EmraxisTheme/Theme";
import {generateHtmlStringForPDF} from "../../../../utils/clinicalnotes"
import { addLoginDetails } from "../../../../Redux/features/login/loginSlice";
import addImage from "../../../../components/Assets/addImage.png";
import ReactDOMServer from "react-dom/server";
import { MenuItem, Select } from "@mui/material";
import downloadImage from "../../../../components/Assets/download.png";
import editImage from "../../../../components/Assets/edit.png";
import copyImage from "../../../../components/Assets/copyImage.png";
import cancelImage from "../../../../components/Assets/cancelImage.png";
import closeImage from "../../../../components/Assets/closedIcon.png";
import CustomAudioPlayer from "../../../../components/CustomAudioPlayer";
import TemplatesView from "./TemplatesView";
import { CLINICAL_NOTES_TEMPLATE_OPTIONS,CLINICAL_NOTE_TEMPLATES } from "../../../../utils/Constant";

const DoctorNotes = ({
  appointment,
  clinicGuid,
  appointment_id,
  patient_id,
  page_src,
  setIsMessagePopupOpen,
  patient
}) => {
  const dispatch = useDispatch();
  const recNotesEndRef = useRef(null);
  var isAiscriptEnable = useSelector(
    (state) => state.feature.isStandardAiActive
  );
  var isStartRecording = useSelector((state) => state.recording.startRecording);
  var showRecoringPopup = useSelector((state) => state.recording.showRecoringPopup);
  var isStopRecording = useSelector((state) => state.recording.stopRecording);
  var loggeduser = useSelector((state) => state.login.loginuserData);
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  var showRecoringPopup = useSelector(
    (state) => state.recording.showRecoringPopup
  );
  var startRecording = useSelector((state) => state.recording.startRecording);
  var confirmBox = useSelector((state) => state.recording.confirmBox);
  var disableButtons = useSelector((state) => state.recording.disableButtons);
  var isVoice2TextActive = useSelector(
    (state) => state.feature.isVoice2TextActive
  );
  var isRecLoading = useSelector((state) => state.recording.isLoading);
  var refreshNotes = useSelector((state) => state.document_upload.refreshNotes);
  var autoSaveAll = useSelector((state) => state.profileSideNavbar.autoSaveAll);
  var documentType = useSelector((state) => state.document_upload.documentType);
  var isDoctorNotesDataAvailable = useSelector(
    (state) => state.profileSideNavbar.isDoctorNotesDataAvailable
  );
  const previewTheme =
    useSelector((state) => state.theme.colors) ||
    theme.filter((a) => a.name === "defaultTheme")[0];
  const textareaRef = useRef(null);
  const [clinicalNoteVersionList, updateClinicalNoteVersionList] = useState([
    "Original",
  ]);
  const [clinicalNoteVersion, UpdateClinicalNoteVersion] = useState(
    clinicalNoteVersionList
      ? clinicalNoteVersionList[clinicalNoteVersionList.length - 1]
      : ""
  );

  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [drNotes, setDrNotes] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [notesFileDeleteID, setNotesFileDeleteID] = useState(0);
  const [notesFileDeleteCnfBox, setNotesFileDeleteCnfBox] = useState(false);
  const [clinicalNoteData, setClinicalNoteData] = useState([]);
  const [allClinicalData, setAllClinicalData] = useState([]);
  const [files, setFiles] = useState([]);
  const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);
  const [isAnyChanges, setIsAnyChanges] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [clinicalDocumentation, setClinicalDocumentation] = useState(false);
  const [showClinicalNotes, setShowClinicalNotes] = useState(false);
  const [showAudioFiles, setShowAudioFiles] = useState(false);
  const [transcriptJson, setTranscriptJson] = useState([{}]);
  const [transcriptJsonHistory, setTranscriptJsonHistory] = useState([{}]);
  const [audioFiles, setAudioFiles] = useState([]);
  const [currentPlaying, setCurrentPlaying] = useState(null); // Track currently playing audio
  const [audioConfirmBox, setAudioConfirmBox] = useState(false);
  const [showRecordDialog, setShowRecordDialog] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [showAudioFilesData, setShowAudioFilesData] = useState(false);
  const [modifiedJson, setModifiedJson] = useState();
  const [originalJsonBeforeEdit, setOriginalJsonBeforeEdit] = useState([{}]);
  const ContentEdit = useRef(false);
  const [modifyClinicalNoteLoading, setUpdateModifyClinicalNoteLoading] =
    useState(false);
  const [latestNote, setLatestNote] = useState(false);
  const [activeContent, setActiveContent] = useState("");
  const latestVersion = [clinicalNoteVersionList].reverse()[0];
  const [isActiveSaveButton,setIsActiveSaveButton]=useState(false);
  var openRecorderPopUp = useSelector((state) => state.recording.openRecorderPopUp);
  var isTemplateview=useSelector((state)=>state.profileSideNavbar.isTemplateview)
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(30);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedFilTemplate, setSelectedFilTemplate] = useState(null);
  const [selectedFileId, setSelectedFileId] = useState(null); 
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [clinicalVersion,setClinicalVersion] = useState([ "Original"]);
  const [selectedClinicalNoteTemplate,setSelectedClinicalNoteTemplate]=useState();
  const [deleteConfirmBox, setDeleteConfirmBox] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [payloadnote,setPayloadnote]=useState();
 var patientName = patient?.FirstName + " " + patient?.MiddleName?.charAt(0) + " " + patient?.LastName
  useEffect(() => {
    let timer;
    setSelectedClinicalNoteTemplate(
   "history_&_physical" 
    );
    if (isRecording) {
      timer = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timer);
      setRecordingTime(0); // Reset time when stopped
    }
    return () => clearInterval(timer);
  }, [isRecording]);

  const toggleRecording = () => {
    setIsRecording(!isRecording);
  };
  
  const formatToIST = (dateString) => {
    const utcDate = new Date(dateString);
    const istDate = new Date(utcDate.getTime() + 330 * 60 * 1000);
    const timeString =  istDate.toLocaleTimeString('en-IN', {
      hour: '2-digit',
      minute: '2-digit',
     hour12: true
    });
    return timeString.replace('am', 'AM').replace('pm', 'PM');
  };
  const formatToISTHistory = (dateString) => {
    const utcDate = new Date(dateString);
    const istDate = new Date(utcDate.getTime() + 330 * 60 * 1000);
    const timeString =  istDate.toLocaleTimeString('en-IN', {
      hour: '2-digit',
      minute: '2-digit',
     hour12: true
    });
    return timeString.replace('am', 'AM').replace('pm', 'PM');
  };
  const formatTime = (timeInSeconds) => {
    const hours = String(Math.floor(timeInSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((timeInSeconds % 3600) / 60)).padStart(2, '0');
    const seconds = String(timeInSeconds % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const handleOpenTemplatesView=()=>{
    dispatch(setIsTemplateview(true));
  }
  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

 
  const handleRowClick = (note) => {

    setSelectedNote(note);
    setSelectedFilTemplate(note.id
      );
   
  };
  const handleRowClicked = (note) => {
   setSelectedNote(note); 
    setSelectedTemplate(note.Id);
      };
    
      let filteredFiles = null;

if (Array.isArray(selectedNote)) {
  filteredFiles = selectedNote.find((file) => file.Id === Number(selectedTemplate));
} else if (selectedNote?.Id === Number(selectedTemplate)) {
  filteredFiles = selectedNote;
}

let filteredSelFiles = null;

if (Array.isArray(selectedNote)) {
  filteredSelFiles = selectedNote.find((file) => file.id
  === Number(selectedFilTemplate));
} else if (selectedNote?.id
  === Number(selectedFilTemplate)) {
  filteredSelFiles = selectedNote;
}

const filesArray = Array.isArray(filteredFiles) ? filteredFiles : [];

      
    
      
  const handleAudioFilesClick = () => {
    setShowAudioFilesData(true);
   
  };
  const handleDeleteClinicalNotes= async()=>{
    setDisableButton(true);
    let deleteClinicalNotesPayload={
      pateintId: patient_id,
      id: payloadnote?.id?payloadnote?.id:payloadnote?.Id
    }
   await store.DeleteClinicalNotes(DataDecode.encryptPayload(deleteClinicalNotesPayload))
   .then((reponse)=>{
    if(reponse.status==200){
      handleFetchTranscriptionFiles();
      GetAllPatientDrNotes();
      dispatch(
        setNotification({
          message: "successfully deleted clinicalNotes",
          status: "success",
          action: true,
        })
      );
      setDisableButton(false);
      setDeleteConfirmBox(false);
    }
    else{
      dispatch(
        setNotification({
          message: "Error while deleting clinicalNotes",
          status: "error",
          action: true,
        })
      );
    }
    setDisableButton(false);

   }).catch((error)=>{
    setDisableButton(false);
    dispatch(
      setNotification({
        message: "Error occurred",
        status: "error",
        action: true,
      })
    );
   })
  };

  useEffect(() => {
    if (!!selectedNote) {
      handleDefaultData(); // Call function to process data
  
      selectedNote?.clinicalNotesRes.forEach((obj, index, array) => {
        if (index === array.length - 1) {
          const content = obj.ListOfClinicalNote || obj.listOfClinicalNote; // Handles both cases
          const versionStatus = obj.VersionStatus || obj.versionStatus; //  Handles both versions
  
          UpdateClinicalNoteVersion(versionStatus);
          setClinicalVersion(versionStatus);
          setLatestNote(true);
        }
      });
    }
  }, [selectedNote]);
 
  const handleDefaultData = () => {
    let transJson = [];
    let content;
  
    if (!!selectedNote) {
      selectedNote?.clinicalNotesRes.forEach((obj, index, array) => {
        if (obj.VersionStatus !== null || obj.versionStatus !== null) {
          transJson.push(obj.VersionStatus || obj.versionStatus);
        }
        if (index === array.length - 1) {
          content = obj.ListOfClinicalNote || obj.listOfClinicalNote; // Handles both cases
          UpdateClinicalNoteVersion(obj.VersionStatus || obj.versionStatus);
          setLatestNote(true);
        }
      });
  
      let versionsList = [...transJson]; // Ensure proper copying
      let clinicalDocumentString = content;
      let clinicalDocumentParsed = [];
      let ClinicalDocuments = [];
  
      try {
        if (clinicalDocumentString && clinicalDocumentString !== "Empty") {
          clinicalDocumentParsed = JSON.parse(clinicalDocumentString);
          let data = clinicalDocumentParsed.reduce((acc, item) => {
            acc[item?.Version] = {
              Version: item?.Version,
              EditedBy: item?.EditedBy,
              Content: item?.Content
                ? typeof item.Content === "string"
                  ? JSON.parse(item.Content)
                  : item.Content
                : {},
            };
            return acc;
          }, {});
  
          let dataObj = data?.Original;
          ClinicalDocuments = dataObj?.Content?.ClinicalDocumentation || [];
        }
      } catch (error) {
       ClinicalDocuments = clinicalDocumentParsed?.ClinicalDocumentation || [];
      }
     setActiveContent(ClinicalDocuments);
      setTranscriptJson(ClinicalDocuments);
      setTranscriptJsonHistory(ClinicalDocuments);
      updateClinicalNoteVersionList(versionsList);
    }
  };
  
 


  const scrollToBottom = () => {
    if (recNotesEndRef.current) {
      recNotesEndRef.current.scrollTop = recNotesEndRef.current.scrollHeight;
    }
  };

  const hanldeCloseClinicalNotes = () => {
    setShowAudioFiles(true);
  };
  
  const handleCloseRecorder = () => {
   dispatch(setShowRecoringPopup(false)) 
  };

  const hanldeEnableRecordIcon = () => {
    if(isAiscriptEnable){
      if (loggeduser?.clinicalNoteModelType === "AiScript") {
         dispatch(setIsTemplateview(false));
        dispatch(setShowRecoringPopup(true));
        setRecConfirmBox(true);
        dispatch(setOpenRecordingPopUp(false))
      } else {
        setAudioConfirmBox(true);
      }
    }else{
      dispatch(setNotification({ message: "Clinical Notes Feature is not enabled for your Facility", status: 'info', action: true }));
    }
  };
  useEffect(() => {
    if (clinicalDocumentation) {
      handleFetchTranscriptionFiles().then((res) => {
        setClinicalDocumentation(false);
      });
    }
  }, [clinicalDocumentation]);
  useEffect(() => {
    if (isRecLoading) scrollToBottom();
  }, [isRecLoading]);

  const handlePlay = (file) => {
    if (currentPlaying === file) {
      setCurrentPlaying(null);
    } else {
      setCurrentPlaying(file);
    }
  };

  const GetClinicalFiles = async () => {
    try {
      let patientId = patient_id;
      await store
        .GetClinicalNotes(DataDecode.encryptPayload(patientId))
        .then((result) => {
          if (result.status === 200) {
            result.json().then((res) => {
              let response = DataDecode.decryptResponse(res);
              let data = JSON.parse(response);
              setClinicalNoteData(data);
            });
          } else {
            setClinicalNoteData([]);
          }
        });
    } catch (err) {
      setClinicalNoteData([]);
      dispatch(
        setNotification({
          message: "Error occurred",
          status: "error",
          action: true,
        })
      );
    }
  };
  const handleUpdateAudioRecordOption = async () => {
    let enableAudioRecording = {
      userId: loggeduser?.userId,
      clinicalNoteModelType: "AiScript",
    };
    await store
      .EnableAudioRecording(DataDecode.encryptPayload(enableAudioRecording))
      .then((result) => {
        if (result.status === 200) {
          setAudioConfirmBox(false);
          dispatch(
            addLoginDetails({
              ...loggeduser,
              clinicalNoteModelType: enableAudioRecording.clinicalNoteModelType,
            })
          );
          dispatch(
            setNotification({
              message: "Enabled Clinical DOcuments",
              status: "success",
              action: true,
            })
          );
          dispatch(setShowRecoringPopup(true));
        }
        if (result.status === 400) {
          dispatch(
            setNotification({
              message: "Error while enabling the  Standard_Ai feature",
              status: "error",
              action: true,
            })
          );
        } else if (result.status !== 200) {
          dispatch(
            setNotification({
              message: "Error while enabling the ClinicalDocumentation",
              status: "error",
              action: true,
            })
          );
        }
      });
  };

  const GetClinicalNotes = () => {
    try {
      store
        .GetClinicalNotes(
          loggedusertoken,
          DataDecode.encryptPayload({
            clinic_guid: clinicGuid,
            appointment_id: appointment_id,
            patient_id: patient_id,
          })
        )
        .then((result) => {
          if (result.status === 200) {
            result.json().then((res) => {
              let response = DataDecode.decryptResponse(res);
              let data = JSON.parse(response);
              setClinicalNoteData(data);
            });
          } else {
            setClinicalNoteData([]);
          }
        });
    } catch (err) {
      setClinicalNoteData([]);
      dispatch(
        setNotification({
          message: "Error occurred",
          status: "error",
          action: true,
        })
      );
    }
  };

  const GetPatientNotesFile = () => {
    store
      .GetPatientFiles(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: clinicGuid,
          patient_id: patient_id,
          appointment_id: appointment_id,
        })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            setFiles(JSON.parse(response));
          });
        } else {
          setFiles([]);
        }
      })
      .catch((err) => {
        setFiles([]);
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };
  
  const handleFetchTranscriptionFiles = async () => {
    let data = { PatientId: patient_id, Id: appointment_id };
    await store
      .GetTranscriptionFiles(DataDecode.encryptPayload(data))
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let audioData = res;
            setAudioFiles(audioData);
          }, {});
        } else {
          dispatch(
            setNotification({
              message: "Error while fetching Audio Files",
              status: "error",
              action: true,
            })
          );
          setAudioFiles([]);
        }
      })
      .catch((err) => {
        setDataLoading(false);
        setAllClinicalData([]);
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };

  const GetAllPatientDrNotes = () => {
    if (allClinicalData.length == 0) {
      setDataLoading(true);
    }
    store
      .GetAllPatientDrNotes(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: clinicGuid,
          patient_id: patient_id,
        })
      )
      .then((result) => {
        setDataLoading(false);
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            setAllClinicalData(JSON.parse(response));

          });
        } else {
          setAllClinicalData([]);
        }
      })
      .catch((err) => {
        setDataLoading(false);
        setAllClinicalData([]);
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (page_src === "appointment" || isDoctorNotesDataAvailable) {
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        await delay(1000);
        await handleFetchTranscriptionFiles().then((result) => {});
        await delay(1000);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (page_src === "patient") {
      GetAllPatientDrNotes();
    }
  }, [refreshNotes]);

  useEffect(() => {
    GetClinicalFiles();
    GetPatientNotesFile();
  }, [refreshNotes]);

  useEffect(() => {
    GetClinicalNotes();
  }, [refreshData]);

  const handleStartRecording = () => {
    dispatch(setShowRecoringPopup(true));
  };

  const handleCloseRecording = () => {
    dispatch(setStartRecording(false));
    dispatch(setRecConfirmBox(true));
  };

  const handleClinicalNotes = (div,fileId) => {
   
    setShowClinicalNotes(true);
    setShowAudioFilesData(true);
  };
  const handleClinicalNotesOpen = (div,fileId) => {
   
    setSelectedFileId(fileId);
    setShowClinicalNotes(true);
    setShowAudioFilesData(true);
    
  };
 
  const handleCloseClinicalNotes = () => {
    setSelectedNote(null);
    setShowAudioFilesData(false);
  };
  const handleCloseEditFunctionality = () => {
    ContentEdit.current = false;
  };

  const handleCloseAudioFiles = () => {
  
    setShowAudioFilesData(false);
  
  };
  const dateFormat = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const hours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const seconds = String(today.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  };
  const handleDownloadPDFPatient = async (sectionsData, patientName, patientId,version) => {
    try {
       const htmlContent = generateHtmlStringForPDF(
         sectionsData,
         
         patientName,
         patientId,
       version
       );
   
       const pdfContainer = document.createElement("div");
       pdfContainer.innerHTML = htmlContent;
       pdfContainer.style.width = "800px"; 
       pdfContainer.style.padding = "20px"; 
       pdfContainer.style.fontSize = "12px"; 
       document.body.appendChild(pdfContainer);
   
      
       const canvas = await html2canvas(pdfContainer, { scale: 2 }); 
       const pdf = new jsPDF("p", "mm", "a4"); 
       const pdfWidth = pdf.internal.pageSize.getWidth();
       const pdfHeight = pdf.internal.pageSize.getHeight();
   
       const footerHeight = 27; 
       const usableHeight = pdfHeight - footerHeight; 
   
       const canvasWidth = canvas.width;
       const canvasHeight = canvas.height;
       const scaleFactor = pdfWidth / canvasWidth;
   
       let position = 0; 
       const totalPages = Math.ceil(canvasHeight / (usableHeight / scaleFactor));
       let currentPage = 1;
   
       while (position < canvasHeight) {
         const cropCanvas = document.createElement("canvas");
         cropCanvas.width = canvasWidth;
   
         
         const segmentHeight = Math.min(
           canvasHeight - position,
           usableHeight / scaleFactor
         );
         cropCanvas.height = segmentHeight;
   
         const ctx = cropCanvas.getContext("2d");
   
        
         ctx.drawImage(
           canvas,
           0,
           position,
           canvasWidth,
           segmentHeight,
           0,
           0,
           cropCanvas.width,
           cropCanvas.height
         );
       
         const croppedImage = cropCanvas.toDataURL("image/png");
         
       
         pdf.addImage(croppedImage, "PNG", 0, 0, pdfWidth, (pdfWidth / canvasWidth) * cropCanvas.height);
         const patient_Id = patient?.patient_op_number;
         const patientName = `${patient?.FirstName || ""} ${patient?.MiddleName?.charAt(0) || ""} ${patient?.LastName || ""}`.trim();
         
         footerContentPatient(pdf, totalPages, currentPage,  patientName, patient_Id,version);
   
         position += cropCanvas.height; 
         currentPage++;
   
        
         if (position < canvasHeight) {
           pdf.addPage();
         }
       }
       
       const rawDate =  selectedNote?.CreationTime; 
       const visitDate = rawDate ? new Date(rawDate).toLocaleDateString("en-US", {
         year: "numeric",
         month: "2-digit",
         day: "2-digit"
       }).replace(/\//g, "") : "UnknownDate";
       let fileName;
       if (version.toLowerCase() === "original") {
        
         fileName = `${patient?.patient_op_number}(${visitDate}).pdf`;
       } else {
       
         fileName = `${patient?.patient_op_number}(${visitDate}${version}).pdf`;
       }
      
       pdf.save(fileName);
   
       dispatch(
         setNotification({
           message: `PDF saved as ${fileName}`,
           status: "success",
           action: true,
         })
       );
   
       document.body.removeChild(pdfContainer);
     } catch (error) {
       dispatch(
         setNotification({
           message: "Error Occurred",
           status: "error",
           action: true,
         })
       );
     }
   };
   const footerContentPatient = (pdf, totalPages, currentPage, patientName, patientId,version) => {
    const pageWidth = pdf.internal.pageSize.width;
     const pageHeight = pdf.internal.pageSize.height;
     const footerHeight = 20; 
     const padding = 5; 
     const lineHeight = 4; 
     const dateTimeText = `Date and Time - ${new Date(
      selectedNote?.CreationTime
        ).toLocaleDateString("en-US", {
       month: "short",
       day: "2-digit",
       year: "numeric",
     })}`;
     const pageNumberText = `Page ${currentPage} / ${totalPages}`;
     
     pdf.setLineWidth(0.2); 
     pdf.line(
         padding,
         pageHeight - footerHeight, 
         pageWidth - padding,
         pageHeight - footerHeight
     );
 
    
     pdf.setFontSize(8);
     pdf.setTextColor(133,135,144); 
    
     pdf.setFont("helvetica", "italic");
    
      pdf.text(`Version - ${version}`, padding, pageHeight - footerHeight + lineHeight);
      const centerX = pageWidth / 2;
     pdf.text(`Patient Name - ${patientName}`, centerX, pageHeight - footerHeight + lineHeight, { align: 'center' });
     pdf.text(`MRN - ${patientId}`, centerX, pageHeight - footerHeight + 2 * lineHeight, { align: 'center' });
 
     
     const rightX = pageWidth - padding;
     pdf.text(pageNumberText, rightX, pageHeight - footerHeight + lineHeight, { align: 'right' });
     pdf.text(dateTimeText, rightX, pageHeight - footerHeight + 2 * lineHeight, { align: 'right' });
 };
  const handleDownloadPDF = async (sectionsData, doctorName, patientName, patientId, version) => {
  try {
      const htmlContent = generateHtmlStringForPDF(
        sectionsData,
        doctorName,
        patientName,
        patientId,
        version
      );
  
      const pdfContainer = document.createElement("div");
      pdfContainer.innerHTML = htmlContent;
      pdfContainer.style.width = "800px"; 
      pdfContainer.style.padding = "20px"; 
      pdfContainer.style.fontSize = "12px"; 
      document.body.appendChild(pdfContainer);
  
     
      const canvas = await html2canvas(pdfContainer, { scale: 2 }); 
      const pdf = new jsPDF("p", "mm", "a4"); 
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
  
      const footerHeight = 27; 
      const usableHeight = pdfHeight - footerHeight; 
  
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const scaleFactor = pdfWidth / canvasWidth;
  
      let position = 0; 
      const totalPages = Math.ceil(canvasHeight / (usableHeight / scaleFactor));
      let currentPage = 1;
  
      while (position < canvasHeight) {
        const cropCanvas = document.createElement("canvas");
        cropCanvas.width = canvasWidth;
  
        
        const segmentHeight = Math.min(
          canvasHeight - position,
          usableHeight / scaleFactor
        );
        cropCanvas.height = segmentHeight;
  
        const ctx = cropCanvas.getContext("2d");
  
       
        ctx.drawImage(
          canvas,
          0,
          position,
          canvasWidth,
          segmentHeight,
          0,
          0,
          cropCanvas.width,
          cropCanvas.height
        );
      
        const croppedImage = cropCanvas.toDataURL("image/png");
        
      
        pdf.addImage(croppedImage, "PNG", 0, 0, pdfWidth, (pdfWidth / canvasWidth) * cropCanvas.height);
        const patient_Id = patient?.patient_op_number;
        const patientName = `${patient?.FirstName || ""} ${patient?.MiddleName?.charAt(0) || ""} ${patient?.LastName || ""}`.trim();
        
        footerContent(pdf, totalPages, currentPage, version, patientName, patient_Id, doctorName);
  
        position += cropCanvas.height; 
        currentPage++;
  
       
        if (position < canvasHeight) {
          pdf.addPage();
        }
      }
      
      const rawDate = audioFiles[0]?.creationTime; 
      const visitDate = rawDate ? new Date(rawDate).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      }).replace(/\//g, "") : "UnknownDate";
      let fileName;
      if (version.toLowerCase() === "original") {
       
        fileName = `${patient?.patient_op_number}(${visitDate}).pdf`;
      } else {
      
        fileName = `${patient?.patient_op_number}(${visitDate}${version}).pdf`;
      }
     
      pdf.save(fileName);
  
      dispatch(
        setNotification({
          message: `PDF saved as ${fileName}`,
          status: "success",
          action: true,
        })
      );
  
      document.body.removeChild(pdfContainer);
    } catch (error) {
      dispatch(
        setNotification({
          message: "Error Occurred",
          status: "error",
          action: true,
        })
      );
    }
  };
  const footerContent = (pdf, totalPages, currentPage, version, patientName, patientId, doctorName) => {

    const pageWidth = pdf.internal.pageSize.width;
    const pageHeight = pdf.internal.pageSize.height;
    const footerHeight = 20; 
    const padding = 5; 
    const lineHeight = 4; 
    const dateTimeText = `Date and Time - ${new Date(
      audioFiles[0]?.creationTime
       ).toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    })}`;
    const pageNumberText = `Page ${currentPage} / ${totalPages}`;
    
    pdf.setLineWidth(0.2); 
    pdf.line(
        padding,
        pageHeight - footerHeight, 
        pageWidth - padding,
        pageHeight - footerHeight
    );

   
    pdf.setFontSize(8);
    pdf.setTextColor(133,135,144); 
   
    pdf.setFont("helvetica", "italic");
   
    pdf.text(`Version - ${version}`, padding, pageHeight - footerHeight + lineHeight);
    pdf.text(`Provider Name - ${doctorName || 'undefined'}`, padding, pageHeight - footerHeight + 2 * lineHeight);

    
    const centerX = pageWidth / 2;
    pdf.text(`Patient Name - ${patientName}`, centerX, pageHeight - footerHeight + lineHeight, { align: 'center' });
    pdf.text(`MRN - ${patientId}`, centerX, pageHeight - footerHeight + 2 * lineHeight, { align: 'center' });

    
    const rightX = pageWidth - padding;
    pdf.text(pageNumberText, rightX, pageHeight - footerHeight + lineHeight, { align: 'right' });
    pdf.text(dateTimeText, rightX, pageHeight - footerHeight + 2 * lineHeight, { align: 'right' });
};

  const handleAudioFIles = (div) => {
    scrollToDiv(div);
    setShowClinicalNotes(false);
  };
  const scrollToDiv = (divId) => {
    const targetDiv = document.getElementById(divId);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    setDrNotes(appointment && appointment?.notes ? appointment?.notes : "");
  }, [appointment]);

  const saveNotes = () => {
    if (drNotes.trim() === "") {
      dispatch(
        setNotification({
          message: "Please enter Notes!",
          status: "error",
          action: true,
        })
      );
    } else {
      setLoading(true);
      store
        .updateDoctorNotes(
          loggedusertoken,
          DataDecode.encryptPayload({
            appointment_id: appointment.appointment_id,
            clinic_guid: clinicGuid,
            notes: drNotes,
            type: "notes",
          })
        )
        .then((result) => {
          setLoading(false);
          setIsAnyChanges(false);
          if (result.status == 204) {
            store.CreateActivityLogs(loggeduser, "Appointment to Patient Profile", `Doctor Notes Updated - ${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(appointment?.appointment_time)} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`,"Update");
            dispatch(
              setNotification({
                message: "Notes Updated!",
                status: "success",
                action: true,
              })
            );
          } else {
            dispatch(
              setNotification({
                message: "Error Occured!",
                status: "error",
                action: true,
              })
            );
          }
        })
        .catch((err) => {
          dispatch(
            setNotification({
              message: "Error occurred",
              status: "error",
              action: true,
            })
          );
        });
    }
  };
  useEffect(() => {
    if (isAnyChanges && drNotes.trim() !== "") {
      saveNotes();
    }
  }, [autoSaveAll]);

  const deleteNotesFile = () => {
    setLoadingDeleteImage(true);
    store
      .DeletePatientFile(
        loggedusertoken,
        DataDecode.encryptPayload({
          clinic_guid: clinicGuid,
          patient_id: patient_id,
          files: notesFileDeleteID,
        })
      )
      .then((result) => {
        setLoadingDeleteImage(false);
        setNotesFileDeleteCnfBox(false);
        if (result.status == 204) {
          store.CreateActivityLogs(loggeduser, "Appointment to Patient Profile", `Doctor Notes File Deleted - ${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(appointment?.appointment_time)} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`,"Delete");
          dispatch(setRefreshNotes(!refreshNotes));
          dispatch(
            setNotification({
              message: "Deleted Successfully!",
              status: "success",
              action: true,
            })
          );
        } else {
          dispatch(
            setNotification({
              message: "Error Occurred!",
              status: "error",
              action: true,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            message: "Error occurred",
            status: "error",
            action: true,
          })
        );
      });
  };
  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const handleVersionPatientDropdown = (selectedVersion) => {
    UpdateClinicalNoteVersion(selectedVersion);
    const sortedVersions = [...selectedVersion].sort((a, b) => {
      const versionToNumber = (v) => (v === "Original" ? 0 : parseInt(v.replace("V", "")));
      return versionToNumber(b) - versionToNumber(a);
    });
    const latestVersion = sortedVersions[0];
    const versionToSet = selectedVersion || latestVersion;  
    setClinicalVersion(versionToSet);
    let content;
    if (!!selectedNote) {
      content =
        selectedNote?.clinicalNotesRes.find(
          (obj) => obj.VersionStatus === selectedVersion
        )?.ListOfClinicalNote
        || {};
      const clinicalDocumentString = content;
      let clinicalDocumentParsed = [];
      let ClnicalDocuments;

      try {
        if (clinicalDocumentString != "Empty") {
          clinicalDocumentParsed = JSON.parse(clinicalDocumentString);
          let data = clinicalDocumentParsed.reduce((acc, item) => {
            acc[item?.Version] = {
              Version: item?.Version,
              EditedBy: item?.EditedBy,
              Content: item?.Content
                ? typeof item.Content === "string"
                  ? JSON.parse(item.Content)
                  : item.Content
                : {},
            };

            return acc;
          }, {});

          let dataObj = data?.Original;

          ClnicalDocuments = dataObj.Content.ClinicalDocumentation;
        } else {
          ClnicalDocuments = [];
        }
      } catch (error) {
        try {
          ClnicalDocuments = clinicalDocumentParsed?.ClinicalDocumentation;
        } catch (error) {
          dispatch(
            setNotification({
              message: "Error While opening ClinicalNotes",
              status: "error",
              action: true,
            })
          );
        }
      }
      setActiveContent(ClnicalDocuments);
      setTranscriptJsonHistory(ClnicalDocuments)
      setTranscriptJson(ClnicalDocuments);
    }
  };
  const handleVersionDropdown = (selectedVersion) => {
    UpdateClinicalNoteVersion(selectedVersion);
    const sortedVersions = [...selectedVersion].sort((a, b) => {
      const versionToNumber = (v) => (v === "Original" ? 0 : parseInt(v.replace("V", "")));
      return versionToNumber(b) - versionToNumber(a);
    });
    const latestVersion = sortedVersions[0];
    const versionToSet = selectedVersion || latestVersion;  
    setClinicalVersion(versionToSet);
    let content;
    if (!!selectedNote) {
      content =
        selectedNote?.clinicalNotesRes.find(
          (obj) => obj.versionStatus === selectedVersion
        )?.listOfClinicalNote
        || {};
      const clinicalDocumentString = content;
      let clinicalDocumentParsed = [];
      let ClnicalDocuments;

      try {
        if (clinicalDocumentString != "Empty") {
          clinicalDocumentParsed = JSON.parse(clinicalDocumentString);
          let data = clinicalDocumentParsed.reduce((acc, item) => {
            acc[item?.Version] = {
              Version: item?.Version,
              EditedBy: item?.EditedBy,
              Content: item?.Content
                ? typeof item.Content === "string"
                  ? JSON.parse(item.Content)
                  : item.Content
                : {},
            };

            return acc;
          }, {});

          let dataObj = data?.Original;

          ClnicalDocuments = dataObj.Content.ClinicalDocumentation;
        } else {
          ClnicalDocuments = [];
        }
      } catch (error) {
        try {
          ClnicalDocuments = clinicalDocumentParsed?.ClinicalDocumentation;
        } catch (error) {
          dispatch(
            setNotification({
              message: "Error While opening ClinicalNotes",
              status: "error",
              action: true,
            })
          );
        }
      }
      setActiveContent(ClnicalDocuments);
      setTranscriptJsonHistory(ClnicalDocuments)
      setTranscriptJson(ClnicalDocuments);
    }
  };
  const addNewLineIndex = (currentKey) => {
    let [sectionText, sectionIdx, summaryIdx] = currentKey?.split("-");
    let indexToInsert = Number(summaryIdx) + 1;

    setModifiedJson((prevData) => {
      const { ClinicalDocumentation } = prevData;
      const { Sections } = ClinicalDocumentation;
      let modifiedSections = Sections;

      const updatedSections = modifiedSections.map((section, index) => {
        if (index == sectionIdx) {
          let tempSummary = section.Summary;
          let modifiedSummary = [
            ...tempSummary.slice(0, indexToInsert),
            {
              SummarizedSegment: " ",
            },
            ...tempSummary.slice(indexToInsert),
          ];
          return {
            ...section,
            Summary: modifiedSummary,
          };
        }

        return section;
      });

      setOriginalJsonBeforeEdit({
        ...ClinicalDocumentation,
        Sections: updatedSections,
      });

      return {
        ...prevData,
        ClinicalDocumentation: {
          ...ClinicalDocumentation,
          Sections: updatedSections,
        },
      };
    });
  };
  const handleEditButton = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    let content =
      selectedNote?.clinicalNotesRes.find(
        (obj) => obj.versionStatus === clinicalNoteVersion
      )?.listOfClinicalNote || {};

    let clinicalDocumentParsed = [];
    let ClnicalDocuments;
    const clinicalDocumentString = JSON.parse(content);
    if (clinicalDocumentString?.ClinicalDocumentation?.Sections.length >= 0) {
      ClnicalDocuments = clinicalDocumentString?.ClinicalDocumentation;
    } else {
      try {
        if (
          clinicalDocumentString != "Empty" ||
          clinicalDocumentString?.ClinicalDocumentation
        ) {
          clinicalDocumentParsed = clinicalDocumentString[0].Content;
          const parsedContent = JSON.parse(clinicalDocumentString[0].Content);

          // Step 2: Access specific sections
          const sections = parsedContent.ClinicalDocumentation;

          ClnicalDocuments = sections;
        } else {
          ClnicalDocuments = [];
        }
      } catch (error) {}
    }

    let arrString = ClnicalDocuments;
    let modifiedArr = arrString?.Sections?.map((section, sectionIndex) => {
      if (section.Summary.length === 0) {
        return { ...section, Summary: [{ SummarizedSegment: " " }] };
      }
      return section;
    });

    let modifiedObject = { Sections: modifiedArr };
    setOriginalJsonBeforeEdit(modifiedObject);

    setModifiedJson({ ClinicalDocumentation: modifiedObject });
    ContentEdit.current = true;
  };
 // switch content to input field
  const handleEditContentButton = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    let content =
      selectedNote?.clinicalNotesRes.find(
        (obj) => obj.VersionStatus === clinicalNoteVersion
      )?.ListOfClinicalNote
      || {};

    let clinicalDocumentParsed = [];
    let ClnicalDocuments;
    const clinicalDocumentString = JSON.parse(content);
    if (clinicalDocumentString?.ClinicalDocumentation?.Sections.length >= 0) {
      ClnicalDocuments = clinicalDocumentString?.ClinicalDocumentation;
    } else {
      try {
        if (
          clinicalDocumentString != "Empty" ||
          clinicalDocumentString?.ClinicalDocumentation
        ) {
          clinicalDocumentParsed = clinicalDocumentString[0].Content;
          const parsedContent = JSON.parse(clinicalDocumentString[0].Content);

          // Step 2: Access specific sections
          const sections = parsedContent.ClinicalDocumentation;

          ClnicalDocuments = sections;
        } else {
          ClnicalDocuments = [];
        }
      } catch (error) {}
    }

    let arrString = ClnicalDocuments;
    let modifiedArr = arrString?.Sections?.map((section, sectionIndex) => {
      if (section.Summary.length === 0) {
        return { ...section, Summary: [{ SummarizedSegment: " " }] };
      }
      return section;
    });

    let modifiedObject = { Sections: modifiedArr };
    setOriginalJsonBeforeEdit(modifiedObject);

    setModifiedJson({ ClinicalDocumentation: modifiedObject });
    ContentEdit.current = true;
  };
  const copyToClipBord = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const clinicalDocumentString = transcriptJson;
     let patientNameArray = "";
    if (!appointment?.patient_name) {
      const exportData = ReactDOMServer.renderToString(
        <HtmlSTringForPDF
          jsonString={clinicalDocumentString}
          patientName_={
            appointment?.patient_name != null
              ? appointment?.patient_name.join(" ")
              : ""
          }
        />
      );
      const tempElement = document.createElement("div");
      tempElement.innerHTML = exportData;

      const formattedText = traverseAndFormatContent(tempElement);

      try {
        await navigator.clipboard.writeText(formattedText);
        dispatch(
          setNotification({
            message: "Copied Clinical Notes",
            status: "success",
            action: true,
          })
        );
      } catch (err) {
        dispatch(
          setNotification({
            message: "Failed to copy content",
            status: "error",
            action: true,
          })
        );
      }
    }
  };
  
  const focusElementById = (id) => {
    const input = document.getElementById(id); // Get the element by id
    if (input) {
      input.focus(); // Focus on the input
    }
  };
  const updateJSON = (key, value) => {
    if (key?.startsWith("summary") && (value === " " || value === "")) {
      let [sectionText, sectionIdx, summaryIdx] = key?.split("-");
      let indexToInsert = Number(summaryIdx) - 1;
      let tempArr = JSON.parse(JSON.stringify(modifiedJson));

      tempArr?.ClinicalDocumentation.Sections.forEach((item, index) => {
        if (index == sectionIdx) {
          item.Summary.forEach((ele, innerIndex) => {
            if (innerIndex == summaryIdx && item.Summary.length > 1) {
              item.Summary.splice(innerIndex, 1);
              focusElementById(`${sectionText}-${sectionIdx}-${indexToInsert}`);
            } else if (item.Summary.length === 1) {
              item.Summary[0].SummarizedSegment = " ";
            }
          });
        }
      });
      setIsActiveSaveButton(true);
      setModifiedJson(tempArr);
      setOriginalJsonBeforeEdit(tempArr?.ClinicalDocumentation);
    } else if (key?.startsWith("section")) {
      let sectionIndex = key?.split("-")[1];

      setModifiedJson((prevData) => {
        const { ClinicalDocumentation } = prevData;
        const { Sections } = ClinicalDocumentation;

        const updatedSections = Sections.map((section, index) => {
          if (index == sectionIndex) {
            return {
              ...section,
              SectionName: value?.split(" ")?.join("_"),
            };
          }
          return section;
        });

        return {
          ...prevData,
          ClinicalDocumentation: {
            ...ClinicalDocumentation,
            Sections: updatedSections,
          },
        };
      });
    } else if (key?.startsWith("summary")) {
      let [sectionText, sectionIdx, summaryIdx] = key?.split("-");

      setModifiedJson((prevData) => {
        const { ClinicalDocumentation } = prevData;
        const { Sections } = ClinicalDocumentation;

        const updatedSections = Sections.map((section, index) => {
          if (index == sectionIdx) {
            return {
              ...section,
              Summary: section.Summary.map((summaryItem, summIndex) => {
                if (summIndex == summaryIdx) {
                  return {
                    ...summaryItem,
                    SummarizedSegment: value,
                  };
                }
                return summaryItem;
              }),
            };
          }
          return section;
        });

        setIsActiveSaveButton(true);

        return {
          ...prevData,
          ClinicalDocumentation: {
            ...ClinicalDocumentation,
            Sections: updatedSections,
          },
        };
      });
    }
  };
  const traverseAndFormatContent = (element) => {
    let result = "";

    for (let node of element.childNodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        result += node.textContent.trim() + "\n";
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.tagName === "DIV" || node.tagName === "P") {
          result += "\n" + traverseAndFormatContent(node) + "\n";
        } else if (node.tagName === "SPAN" || node.tagName === "H3") {
          result += node.textContent.trim() + "\n";
        } else {
          result += traverseAndFormatContent(node);
        }
      }
    }

    return result.trim();
  };
  const HtmlSTringForPDF = ({ jsonString, patientName_ }) => {
    let patientNameArray = patientName_.split(" ");
    let patientLastName = patientNameArray[patientNameArray.length - 1];

    return (
      <div style={{ width: "750px", padding: "12px" }}>
        <div className="!font-['Open Sans'] text-center">
          <h3 className="text-[#005F74] !font-['Open Sans'] text-[20px]">
            Clinical Notes
          </h3>
        </div>
        {jsonString?.Sections?.map((section, sectionIndex) => {
          return (
            <div className="p-2 !leading-6" key={`section-${sectionIndex}`}>
              <span className="text-[#005F74] !font-['Open Sans'] !text-[14px] ">
                {`${section?.SectionName}`?.replaceAll("_", " ")}
              </span>

              <div className="ml-8 !mt-[10px]">
                {section?.Summary?.map((item, summaryIndex) => (
                  <p
                    className="text-[#000000] !font-['Open Sans'] !text-[12px] text-justify !leading-5"
                    key={`${section.SectionName}-${summaryIndex}`}
                  >
                    - {item?.SummarizedSegment}
                  </p>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const handlePatientSaveNoteButton = async () => {
    setIsActiveSaveButton(true);
    modifiedJson.ClinicalDocumentation.Sections.forEach((item, index) => {
      for (let i = item?.Summary?.length - 1; i >= 0; i--) {
        if (item?.Summary?.[i].SummarizedSegment.trim() === "") {
          item.Summary.splice(i, 1);
        }
      }
    });
    setUpdateModifyClinicalNoteLoading(true);
    let payload = {
      UserId: loggeduser?.userId,
      modifiedClinicalNote: `${JSON.stringify(modifiedJson)}`,
      editedBy: loggeduser?.userId,
      Id: selectedNote?.Id,
      Version: `V${clinicalNoteVersionList?.length}`,
    };
    await store
      .UpdateClinicalNotes(DataDecode.encryptPayload(payload))
      .then((response) => {
        if (response?.status === 200) {
          ContentEdit.current = false;
          handleDefaultData();
           dispatch(
            setNotification({
              message: "Clinicalnotes updated successfully",
              status: "success",
              action: true,
            })
          );
          setIsActiveSaveButton(false);
         window.location.reload();
        } else {
          dispatch(
            setNotification({
              message: "Clinical note modification failed!",
              status: "error",
              action: true,
            })
          );
          setUpdateModifyClinicalNoteLoading(false);
        }
      })
      .catch((e) => {
        setUpdateModifyClinicalNoteLoading(false);
      });
  };
  const handleSaveNoteButton = async () => {
    setIsActiveSaveButton(true);
    modifiedJson.ClinicalDocumentation.Sections.forEach((item, index) => {
      for (let i = item?.Summary?.length - 1; i >= 0; i--) {
        if (item?.Summary?.[i].SummarizedSegment.trim() === "") {
          item.Summary.splice(i, 1);
        }
      }
    });
    setUpdateModifyClinicalNoteLoading(true);

    let payload = {
      UserId: loggeduser?.userId,
      modifiedClinicalNote: `${JSON.stringify(modifiedJson)}`,
      editedBy: loggeduser?.userId,
      Id: selectedNote?.id,
      Version: `V${clinicalNoteVersionList?.length}`,
    };

    await store
      .UpdateClinicalNotes(DataDecode.encryptPayload(payload))
      .then((response) => {
        if (response?.status === 200) {
          ContentEdit.current = false;
          handleDefaultData();
          dispatch(
            setNotification({
              message: "Clinicalnotes updated successfully",
              status: "success",
              action: true,
            })
          );
          setIsActiveSaveButton(false);
         window.location.reload();
        } else {
          dispatch(
            setNotification({
              message: "Clinical note modification failed",
              status: "error",
              action: true,
            })
          );
          setUpdateModifyClinicalNoteLoading(false);
        }
      })
      .catch((e) => {
        setUpdateModifyClinicalNoteLoading(false);
      });
  };
  const handleCancelEditButton = () => {
    ContentEdit.current = false;
    setModifiedJson({});
  };
  const compareJson = () => {
    if (Object.keys(modifiedJson).length) {
      return (
        JSON.stringify(transcriptJson) ===
        JSON.stringify(modifiedJson?.ClinicalDocumentation)
      );
    }

    return false;
  };
  const handleUploadButton = (type) => {
    dispatch(setNewAppointmentId(appointment_id));
    dispatch(setDocumentType({ ...documentType, doctor_note: true }));
    dispatch(setIsCameraUpload(type === "capture" ? true : false));
    if (
      appointment_id == 0 ||
      (appointment && appointment.status == "Completed")
    ) {
      setIsMessagePopupOpen((p) => !p);
    } else {
      dispatch(setShowUploadPopup(true));
    }
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [drNotes]);

  return (
    <>
      {confirmBox && (
        <div className="fixed inset-0 flex justify-center items-center p-8 z-[999999] rounded-xl">
          <div className="absolute inset-0 bg-black opacity-25 rounded-xl"></div>
          <div
            className="relative bg-white shadow-xl rounded-xl"
            style={{ width: "400px" }}
          >
            <div className="pl-8 pt-4 pr-8">
              <p className="pt-4 text-lg font-semibold text-center">
                Your recording will be deleted, are you sure you want to close?
              </p>
              <div className="my-10 flex justify-center items-center">
                <button
                  id="btnCancelCfmRecordingPopup"
                  className={`mr-2 px-4 py-1 font-semibold rounded-md shadow-md ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}
                  onClick={() => {
                    dispatch(setRecConfirmBox(false));
                  }}
                >
                  Cancel
                </button>
                <button
                  id="btnCancelCfmProccedPopup"
                  className={`mr-2 py-1 px-4 font-semibold rounded-md shadow-md ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}
                  onClick={() => {
                    dispatch(setRecConfirmBox(false));
                    dispatch(setShowRecoringPopup(false));
                    dispatch(setStartRecording(false));
                  }}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
         {deleteConfirmBox && (
                            <div id="UserPicDivConfirmBox" tabIndex="-1" aria-hidden="true" className="fixed left-1/2 top-1/2 w-full max-w-sm transform -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%] sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%]">
                                <div id="UserPicDivConfirmBoxContentDialog" className="relative p-4 w-full max-w-md h-full md:h-auto">
                                    <div id="UserPicDivConfirmBoxContentDialogBox" className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                                        <svg id="UserPicSvgConfirmBoxIcon" className="text-red-800 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path>
                                        </svg>
                                         <p id="UserPicPConfirmBoxMessage" className={`mb-4`} style={{ fontFamily: previewTheme.fontFamily }}>Are you sure you want to delete this ClinicalNote?</p>
                                        <div id="UserPicDivConfirmBoxButtons" className="flex justify-center items-center space-x-4">
                                            <button
                                                id="UserPicButtonConfirmBoxCancel"
                                                onClick={() => setDeleteConfirmBox(false)}
                                                className={`py-2 px-3 text-sm font-medium rounded-lg border ${previewTheme?.cancelBtnColor || 'text-gray-700'} ${previewTheme?.cancelBtnBgColor || 'bg-white'} ${disableButton ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            >
                                                No, cancel
                                            </button>
                                            <button
                                                id="UserPicButtonConfirmBoxConfirm"
                                                type="button"
                                                onClick={()=>{handleDeleteClinicalNotes();}}
                                                className={`py-2 px-3 text-sm font-medium rounded-lg border ${previewTheme?.saveBtnColor || 'text-white'} ${previewTheme?.saveBtnBgColor || 'bg-red-600'} ${disableButton ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            >
                                                Yes, I'm sure
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
      {notesFileDeleteCnfBox && (
        <div
          id="delete"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
              <svg
                className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p className="mb-4 text-gray-500 dark:text-gray-300">
                Are you sure you want to delete this file?
              </p>
              <div className=" flex justify-center items-center">
                <button
                  id="btnCancelCfmDeletePopup"
                  disabled={loadingDeleteImage}
                  className={`mr-2 py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10 dark:focus:ring-gray-600 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}
                  onClick={() => {
                    setNotesFileDeleteCnfBox(false);
                  }}
                >
                  No, cancel
                </button>
                <button
                  id="btnYesCfmDeletePopup"
                  type="button"
                  onClick={deleteNotesFile}
                  disabled={loadingDeleteImage}
                  className={`py-2 px-3 text-sm font-medium text-center rounded-lg focus:ring-4 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}
                >
                  Yes, I'm sure{" "}
                  {loadingDeleteImage && (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 ml-2 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <>
     
        <>
          {audioConfirmBox && (
            <div className="fixed inset-0 flex justify-center items-center p-8 z-[999999]">
              <div className="absolute inset-0 bg-black opacity-25 "></div>
              <div
                className="relative bg-white shadow-xl rounded-xl"
                style={{ width: "400px" }}
              >
                <div className="pl-8 pt-4 pr-8 rounded-xl">
                  <p className="pt-2 text-lg font-semibold text-center">
                    Do You Want to Enable the AI Generated Clinical Notes?
                  </p>
                  <div className="my-10 flex justify-center items-center">
                    <button
                      id="btnCancelCfmRecordingPopup"
                      className={`mr-2 px-3 py-1 font-semibold rounded-md shadow-md ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}
                      onClick={() => {
                        setAudioConfirmBox(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      id="btnCancelCfmProccedPopup"
                      className={`mr-2  py-1 px-3 font-semibold rounded-md shadow-md ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}
                      onClick={() => {
                        handleUpdateAudioRecordOption();
                      }}
                    >
                      Enable
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
        <>
          {showRecoringPopup && (
            <div className="fixed inset-0 flex justify-center items-center p-8 z-[999998]">
              <div className="absolute inset-0 bg-black opacity-25 "></div>
              <div
                className="relative bg-white shadow-xl rounded-sm"
                style={{ width: "600px" }}
              >
              {
                openRecorderPopUp  && (
                  <div className="text-right mt-[12px]">
                  <button
                    id="btnCloseRecordingPopup"
                    className="text-red-700 sm:justify-left disabled:cursor-not-allowed disabled:opacity-50"
                    disabled={disableButtons}
                    onClick={handleCloseRecording}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-7 w-7 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </button>
                  </div>
                 
                )
              }
              {
                !openRecorderPopUp  && (
                  <div className="text-right mt-[12px]">
                  <button
                    id="btnCloseRecorderPopup"
                    className="text-red-700 sm:justify-left disabled:cursor-not-allowed disabled:opacity-50"
                    disabled={disableButtons}
                    onClick={handleCloseRecorder}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-7 w-7 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </button>
                  </div>
                 
                )
              }
              
               <div className={`w-full w-[600px]  pl-16 pr-16  pt-4  bg-white rounded-lg shadow-lg text-center`}>
      <h2 className="text-xl font-semibold mb-4">Audio Recorder</h2>
      <div className="min-h-[200px]">
                  <EMRAudioRecorder
                    patient_id={patient_id}
                    appointment_id={appointment_id}
                    setRefreshData={setRefreshData}
                    setClinicalDocumentation={setClinicalDocumentation}
                  />
                </div>
                </div>
              </div>
            </div>
          )}
        </>
        {isTemplateview &&
        <TemplatesView
          selectedClinicalNoteTemplate={selectedClinicalNoteTemplate}
          setSelectedClinicalNoteTemplate={setSelectedClinicalNoteTemplate}
          hanldeEnableRecordIcon={hanldeEnableRecordIcon}
          />

        }
      </>

      <div className="shadow-lg rounded-md ">
        <div className={`flex justify-between bg-[#e2e8f0] ${previewTheme.headingTitleColor} rounded-t-lg text-lg font-semibold py-1 px-2`} style={{ fontFamily: previewTheme.fontFamily }}>
          <div>
            <p id="DoctorNameNotesId" className="font-semibold text-lg">
              Doctor Notes
            </p>
          </div>
          <div>
          
            { appointment?.status !== "Completed" &&
              appointment_id > 0 && (
                <button id="btnStartRecording" 
                 title="Record"
                onClick={handleOpenTemplatesView}  
                type="button" 
                className={`${previewTheme.borderTheame}  border-2 py-2 pl-3 text-sm font-medium text-gray-500 bg-white rounded-l   hover:bg-gray-100   hover:text-gray-900  dark:bg-gray-700 dark:text-gray-300 `}>
                          AI Scribe
                          <img
                    id="AudioRecordIcon"
                    className={`size-5 mb-1 mr-2 inline cursor-pointer text-red-700 ml-[6px]`}
                    src={"image/AudioRecordIcon.png"}
                  />
                        </button>
               
              )}
            <span title="Upload" className="pl-[14px]">
              <svg
                id="DesktopBtnUploadDrNotes"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleUploadButton("upload")}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className={`size-7 mb-1 mr-2 inline cursor-pointer text-red-700 `}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                />
              </svg>
            </span>
            <span title="Capture">
              <svg
                id="DesktopBtnCaptureDrNotes"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleUploadButton("capture")}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className={`inline mb-1 size-7 cursor-pointer text-red-700`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                />
              </svg>
            </span>
          </div>
        </div>
        {isDoctorNotesDataAvailable && (
          <>
            {page_src === "appointment" ? (
              <div className="w-full rounded-lg bg-gray-50">
                {appointment_id > 0 ? (
                  <>
                    <div>
                    {isAiscriptEnable &&loggeduser?.clinicalNoteModelType === "AiScript" && (
                      <div
                        ref={recNotesEndRef}
                        // className="py-2 bg-white rounded-t-lg dark:bg-gray-800 min-h-36"
                      >
                      {isVoice2TextActive && (
                        <>
                          {clinicalNoteData.filter(
                            (a) => a.notes !== "N/A" && a.notes !== ""
                          ).length > 0 ? (
                            <>
                              {clinicalNoteData
                                .filter(
                                  (a) => a.notes !== "N/A" && a.notes !== ""
                                )
                                .map((cn, ind) => (
                                  <div
                                    key={"cn-" + ind}
                                    className="flex justify-between my-1 "
                                  >
                                    <div>
                                      <p className="break-words">
                                        <span className="font-semibold">
                                          Note {ind + 1}:{" "}
                                        </span>{" "}
                                        {cn.notes}
                                      </p>
                                    </div>
                                    <div>
                                      {cn.file_path && (
                                        <EMRAudioPlayer
                                          source={
                                            process.env
                                              .REACT_APP_EMRAXIS_S3_URL +
                                            "/" +
                                            cn.file_path
                                          }
                                        />
                                      )}
                                    </div>
                                   </div>
                                ))}
                            </>
                          ) : (
                            <p></p>
                          )}
                          {isRecLoading && (
                            <LoadingSpinner text="Generating Notes" />
                          )}
                        </>
                      )}
                      {audioFiles.length > 0 && (
                        <div className="pt-[0.5rem]  bg-gray-100">
                          <div className="">
                            {/* Table Section */}
                            <div
                              className={` bg-[#FFFFFF]  ${
                                !selectedNote  ? "w-full" : "w-full" 
                              }`}
                            >
                              {!selectedNote && (
                                <table className="min-w-full bg-white border rounded-lg shadow-sm">
                                  <thead>
                                    <tr className="bg-red-800 text-white h-[4.65rem]">
                                      <th className="py-3 px-4 text-left font-medium">
                                        Visit/Upload Details
                                      </th>
                                      {selectedNote && !showAudioFilesData && (
                                        <th className="py-3 px-4 text-left font-medium">
                                          Type
                                        </th>
                                      )}
                                      <th className="py-3 px-4 text-left font-medium">
                                        Last Modified by
                                      </th>
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="py-3 px-4 text-left font-medium">
                                          Doctor
                                        </th>
                                      )}
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="py-3 px-4 text-left font-medium">Type</th>
                                      )}
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="py-3 px-4 text-left font-medium">
                                        Action
                                        </th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {audioFiles.map((note, index) => {
                                      const isModifiedTime = formatToIST(note.lastModifiedDate);
                                      const istTimeString = formatToIST(note.creationTime);
                                      const clinicalNoteatemplate= note?.typeOfClinicalNotes !== ""?
                                      note?.typeOfClinicalNotes .replace(/_/g, ' ')  
                                      .replace(/\b\w/g, (char) => char.toUpperCase())
                                     :"History & Physical";
                                      return(
                                      <tr 
                                        id={index}
                                        key={index}
                                        className={`border-b last:border-b-0 cursor-pointer ${
                                        selectedRowIndex === index ? "bg-blue-100" : "hover:bg-gray-100"
                                      }`} 
                                      
                                      >
                                        <td className="py-3 px-4"
                                          onClick={() => {
                                            handleRowClick(note);
                                            setSelectedRowIndex(index); 
                                         }}>
                                          <span className="text-black font-normal ml-2">
                                        
                                            {`${new Date(
                                              note.creationTime
                                            ).toLocaleDateString("en-US", {
                                              month: "short",
                                              day: "2-digit",
                                              year: "numeric",
                                            })} `}
                                          </span>
                                          <br />
                                          <span className="text-[#8C8C8C] font-normal ml-2">
                                          {istTimeString}
                                           
                                          </span>
                                        </td>
                                        {selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4"
                                            onClick={() => {
                                              handleRowClick(note);
                                              setSelectedRowIndex(index); 
                                           }}>
                                              {note.type}
                                            </td>
                                          )}
                                        <td className="py-3 px-4"
                                          onClick={() => {
                                            handleRowClick(note);
                                            setSelectedRowIndex(index); 
                                         }}>
                                          {note.lastModifiedBy} <br />
                                          <span className="text-[#8C8C8C] font-normal">
                                          {`${new Date(
                                              note.lastModifiedDate
                                            ).toLocaleDateString("en-US", {
                                              month: "short",
                                              day: "2-digit",
                                              year: "numeric",
                                            })} `}
                                           
                                          {isModifiedTime}
                                            
                                          </span>
                                        </td>
                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4"
                                            onClick={() => {
                                              handleRowClick(note);
                                              setSelectedRowIndex(index); 
                                           }}>
                                              {appointment.doctor_name}
                                            </td>
                                          )}
                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4">
                                              {clinicalNoteatemplate}
                                            </td>
                                          )}

                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4">
                                              <button
                                               className=" pl-2 text-[#9B1C1C] disabled:text-[#D88C8C]"
                                               onClick={(e)=>{
                                                setDeleteConfirmBox(true);
                                                setPayloadnote(note);
                                               }}
                                               >
                                              <svg
                                                 id="BtnDeleteAppIcon"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 24 24"
                                                 fill="currentColor"
                                                 className="w-6 h-6 "
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                              </button>
                                            </td>
                                          )}
                                      </tr>
                              )})}
                                  </tbody>
                                </table>
                              )}
                              
                            </div>
                           
                            <div className="grid grid-cols-2 !overflow-hidden ">
  {!showAudioFilesData  ? (
    <>
      <div>  {!showAudioFilesData && selectedNote && (
                                <table className="min-w-full bg-white border rounded-lg shadow-sm">
                                  <thead>
                                    <tr className="md:text-[14px] lg:text-[14px] xl:text-[16px] bg-red-800 text-white h-[4.65rem]">
                                      <th className="py-3 px-4 text-left font-medium">
                                        Visit/Upload Details
                                      </th>
                                      {selectedNote && !showAudioFilesData && (
                                        <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium">
                                          Type
                                        </th>
                                      )}
                                      <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium">
                                        Last Modified by
                                      </th>
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium">
                                          Provider
                                        </th>
                                      )}
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium"></th>
                                      )}
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium">
                                         
                                        </th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {audioFiles.map((note, index) => {
                                     
                                      const istTimeString = formatToIST(note.creationTime);
                                      const clinicalNoteatemplate= note?.typeOfClinicalNotes !== ""?
                                      note?.typeOfClinicalNotes .replace(/_/g, ' ')  
                                      .replace(/\b\w/g, (char) => char.toUpperCase())
                                     :"History & Physical";
                                      return(
                                      <tr
                                       id={index}
                                        key={index}
                                        onClick={() => {
                                          handleRowClick(note);
              setSelectedRowIndex(index); 
            }}
                                       
                                        
                                        className={`border-b last:border-b-0 cursor-pointer ${
              selectedRowIndex === index ? "bg-blue-100" : "hover:bg-gray-100"
            }`}
                                       
                                      >
                                        <td className="py-3 px-4 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                          <span className="text-black font-normal ml-2 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                            {`${new Date(
                                              note.creationTime
                                            ).toLocaleDateString("en-US", {
                                              month: "short",
                                              day: "2-digit",
                                              year: "numeric",
                                            })} `}
                                          </span>
                                          <br />
                                          <span className="text-[#8C8C8C] font-normal ml-2 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                           {istTimeString}
                                          </span>
                                        </td>
                                        {selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                              {clinicalNoteatemplate}
                                              </td>
                                          )}
                                        <td className="py-3 px-4 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                          {note.lastModifiedBy} <br />
                                          <span className="text-sm text-gray-500 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                            {note.modifiedDate}{" "}
                                            {note.modifiedTime}
                                          </span>
                                        </td>
                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                              {note.provider}
                                            </td>
                                          )}
                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                              {note.template}
                                            </td>
                                          )}

                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4">
                                              <button className="text-gray-500 hover:text-gray-700">
                                                
                                              </button>
                                            </td>
                                          )}
                                      </tr>
                              )})}
                                  </tbody>
                                </table>
                              )}</div>
      <div>{selectedNote && (
                              <div className=" border bg-[#FFFFFF] border-gray-300 rounded-lg shadow-md font-sans">
                                {/* Header Section */}
                                <div className="!bg-[#D9D9D9] flex justify-between items-center bg-gray-100 p-3 border-b border-gray-300 h-[4.65rem]">
                                  <div>
                                    <div className="flex items-center space-x-2">
                                      <h3 className=" text-xl font-bold text-[#A31B1B]">
                                        Clinical Notes
                                      </h3>
                                      <p className="text-sm text-gray-600 pt-[3px]">
                                      {appointment.doctor_name}
                                      </p>
                                    </div>
                                   
                                  </div>
                                  <div className="flex space-x-2">
                                  {!ContentEdit.current && (
                                    <div className="flex gap-[4px]">
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="AddImageIcon"
                                       
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="CopyImageIcon"
                                        onClick={(e) => {
                                        copyToClipBord(e);
                                       }}
                                        src={copyImage}
                                      />
                                    </button>
                                    {clinicalNoteVersionList.length > 1 && (
                            <Select
                              labelId="audio-transcipt-clinical-note-version-select"
                              id="audio-transcipt-clinical-note-version-select"
                              value={
                                latestNote ? clinicalNoteVersion : latestVersion
                              }
                              label=""
                              onChange={(e) => {
                                setLatestNote(true);
                                handleVersionDropdown(e?.target?.value);
                              }}
                              size="small"
                              style={{
                                height: 30,
                                backgroundColor: "#fff",
                                marginTop: "3px",
                              }}
                              inputProps={{
                                sx: {
                                  fontWeight: "500",
                                  maxHeight: "50px",
                                },
                                "data-testid":
                                  "audio-transcipt-clinical-note-version-types",
                              }}
                              disabled={!clinicalNoteVersionList}
                            >
                              {clinicalNoteVersionList?.map((version) => (
                                <MenuItem
                                  value={version}
                                  key={version}
                                  id={version}
                                >
                                  {version}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="EditImageIcon"
                                        onClick={(e) => {
                                          handleEditButton(e, selectedNote);
                            }}
                                        src={editImage}
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="DownloadImageIcon"
                                        onClick={() => handleDownloadPDF(transcriptJson?.Sections, appointment.doctor_name,patientName ,patient_id, clinicalVersion)}
                                        src={downloadImage}
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="CancelImageIcon"
                                        onClick={handleCloseClinicalNotes}
                                     
                                        src={cancelImage}
                                      />
                                    </button>
                                    </div>
                            )}
                            {ContentEdit.current && (
                        <div className="flex justify-between items-end space-x-2">
                          <div>
                            <button
                            id="btnSaveNote"
                              onClick={handleSaveNoteButton}
                              disabled={
                                modifyClinicalNoteLoading ||
                                 compareJson()
                              }
                              className={
                                isActiveSaveButton
                                  ? "bg-[#b81f1f] text-[#f6f3f3] border-red-700 hover:bg-red-500 w-32 active:bg-white-700 font-medium py-2 px-4 h-100% w-100% rounded transition duration-300 ease-in-out rounded-xl"
                                  : "bg-[#cbc0c0] text-[#8e7979] border-red-700 w-32 active:bg-white-700 font-medium py-2 px-4 h-100% w-100% rounded transition duration-300 ease-in-out rounded-xl"
                              }
                            >
                              Save
                            </button>{" "}
                          </div>
                          <div>
                            <button
                            id="btnCancelEdit"
                              onClick={handleCancelEditButton}
                              className="bg-[#fffefe] text-[#e26666] border-red-700 hover:bg-[#e8d2d2] w-32 font-medium py-2 px-4 rounded transition duration-300 ease-in-out rounded-xl"
                            >
                              Cancel
                            </button>{" "}
                          </div>
                          <div>
                            <button className="text-gray-600 hover:text-gray-800 text-lg">
                              <img
                                id="AudiohandleCloseEditFunctionality"
                                onClick={handleCloseEditFunctionality}
                              
                                src={cancelImage}
                              />
                            </button>
                          </div>
                        </div>
                      )}
                                  </div>
                                </div>

                              

                                {/* Patient Info */}

                                <div
                                  style={{ display: "-webkit-inline-box" }}
                                  
                                >
                                  {!showAudioFilesData && (
                                    <div className="mt-[16rem]">
                                      <button
                                        id="AudioFilesBtn"
                                       onClick={() => {
                                          handleClinicalNotes(
                                            "showClinicalNotes_Id",selectedNote.Id
                                          );
                                        }}
                                      >
                                     
                                        <img
                                          id="AudioEditIcon"
                                          className={`size-auto mb-1 mr-2 inline cursor-pointer text-red-700`}
                                          src={AudioFiles}
                                        />
                                      </button>
                                    </div>
                                  )}

                                  <div id="clinical-notes-content">
                                    <div
                                      className={` ${
                                        !showAudioFilesData
                                          ? "ml-[1rem]"
                                          : "pl-[1rem]"
                                      } `}
                                    >
                                     
                                      <div>
                                        <ul className="list-disc list-inside   mt-2">
                                          <div className=" mb-4">
                                            {isAiscriptEnable && (
                                              <div
                                                id={"showClinicalNotes_Id"}
                                                className=" flex justify-between hidden sm:inline grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2"
                                              >
                                                <div className="col-span-9 justify-self-start ">
                                                  {!ContentEdit.current && audioFiles &&
                                                  audioFiles.length > 0 ? (
                                                    <>
                                                      {audioFiles?.slice(0,1)?.map(
                                                        (file, index) => {
                                                          if (
                                                            file?.listOfClinicalNote !=
                                                            "Empty"
                                                          ) {
                                                            const clinicalDocumentString =
                                                              file?.listOfClinicalNote;
                                                            let clinicalDocumentParsed =
                                                              [];
                                                            let ClnicalDocuments;

                                                            try {
                                                              if (
                                                                clinicalDocumentString !=
                                                                "Empty"
                                                              ) {
                                                                clinicalDocumentParsed =
                                                                  JSON.parse(
                                                                    clinicalDocumentString
                                                                  );
                                                                let data =
                                                                  clinicalDocumentParsed.reduce(
                                                                    (
                                                                      acc,
                                                                      item
                                                                    ) => {
                                                                      acc[
                                                                        item?.Version
                                                                      ] = {
                                                                        Version:
                                                                          item?.Version,
                                                                        EditedBy:
                                                                          item?.EditedBy,
                                                                        Content:
                                                                          item?.Content
                                                                            ? JSON?.parse(
                                                                                JSON.parse(
                                                                                  item?.Content
                                                                                )?.[0]
                                                                                  ?.Content
                                                                              )
                                                                            : {},
                                                                      };

                                                                      return acc;
                                                                    },
                                                                    {}
                                                                  );
                                                                let dataObj =
                                                                  data?.Original;

                                                                ClnicalDocuments =
                                                                  dataObj
                                                                    .Content
                                                                    .ClinicalDocumentation;
                                                              } else {
                                                                ClnicalDocuments =
                                                                  [];
                                                              }
                                                            } catch (error) {
                                                             
                                                            }

                                                            const date =
                                                              new Date(
                                                                file?.creationTime
                                                              );

                                                            const day = String(
                                                              date.getDate()
                                                            ).padStart(2, "0");
                                                            const month =
                                                              String(
                                                                date.getMonth() +
                                                                  1
                                                              ).padStart(
                                                                2,
                                                                "0"
                                                              );
                                                            const year = String(
                                                              date.getFullYear()
                                                            ).slice(-2);
                                                            return (
                                                              <div className="content-container pt-2 pb-2 pr-4">
                                                                <ul>
                                                                  <li className="md:text-[14px] lg:text-[14px] xl:text-[16px]"
                                                                    key={index}
                                                                  >
                                                                    <div className="grid grid-row-10 gap-4 pr-[5rem]">
                                                                     

                                                                      {transcriptJson?.Sections?.map(
                                                                        (
                                                                          section,
                                                                          sectionIndex
                                                                        ) => (
                                                                          <div
                                                                            className="py-2 pr-2"
                                                                            key={`section-${sectionIndex}`}
                                                                          >
                                                                          <span
  className="!font-bold"
  onInput={(e) => {
    e.stopPropagation();
    e.preventDefault();
  }}
  suppressContentEditableWarning={true}
>
  {toTitleCase(section?.SectionName.replaceAll("_", " "))}
</span>
                                                                            
                                                                            <ul
                                                                              className="!list-disc ml-8"
                                                                              id="clinical_note_unordered_list"
                                                                            >
                                                                              {section?.Summary?.filter(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item?.SummarizedSegment
                                                                              )?.map(
                                                                                (
                                                                                  item,
                                                                                  summaryIndex
                                                                                ) => (
                                                                                  <p
                                                                                    key={`${section.SectionName}-${summaryIndex}`}
                                                                                    onInput={(
                                                                                      e
                                                                                    ) => {
                                                                                      const liElement =
                                                                                        e.currentTarget;
                                                                                      if (
                                                                                        !liElement?.textContent
                                                                                      ) {
                                                                                        liElement?.remove();
                                                                                      }
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                      event
                                                                                    ) => {
                                                                                      if (
                                                                                        event.key ===
                                                                                          "Enter" &&
                                                                                        !event.shiftKey
                                                                                      ) {
                                                                                        event.preventDefault();
                                                                                      }
                                                                                    }}
                                                                                    suppressContentEditableWarning={
                                                                                      true
                                                                                    }
                                                                                   
                                                                                    className="relative pl-4 focus"
                                                                                  >
                                                                                    <span className="absolute left-0">
                                                                                      •
                                                                                    </span>
                                                                                    {
                                                                                      item?.SummarizedSegment
                                                                                    }
                                                                                  </p>
                                                                                )
                                                                              )}
                                                                            </ul>
                                                                          </div>
                                                                        )
                                                                      )}
                                                                    </div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            );
                                                          } else {
                                                            return (
                                                              <ul>
                                                                <li
                                                                  key={index}
                                                                  className="mb-4 flex  text-center"
                                                                >
                                                                  <p className="font-bold">
                                                                    {`Clinical Notes.${
                                                                      index + 1
                                                                    }`}{" "}
                                                                  </p>
                                                                </li>
                                                                <li>
                                                                  {" "}
                                                                  <p
                                                                    id={
                                                                      "emptyErrormsgId"
                                                                    }
                                                                    className="mb-4 flex justify-center items-center text-center"
                                                                  >
                                                                    No Clinical
                                                                    Notes are
                                                                    available.
                                                                  </p>
                                                                </li>
                                                              </ul>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </>
                                                  ) : (
                                                   ""
                                                  )}
                                                  {ContentEdit.current &&
                                      originalJsonBeforeEdit?.Sections?.map(
                                        (section, sectionIndex) => {
                                          return (
                                            <div
                                              className="p-2"
                                              key={`section-${sectionIndex}`}
                                            >
                                              <span
                                                className="!font-semibold"
                                                onInput={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  updateJSON(
                                                    `section-${sectionIndex}`,
                                                    e.target.innerText
                                                  );
                                                }}
                                                suppressContentEditableWarning={
                                                  true
                                                }
                                              >
                                                {`${section?.SectionName}`?.replaceAll(
                                                  "_",
                                                  " "
                                                )}
                                              </span>
                                              <ul
                                                className="!list-disc ml-8"
                                                id="clinical_note_unordered_list"
                                              >
                                                {section?.Summary?.filter(
                                                  (item) =>
                                                    item?.SummarizedSegment
                                                )?.map((item, summaryIndex) => (
                                                  <li
                                                    contentEditable={
                                                      ContentEdit.current
                                                    }
                                                    id={`summary-${sectionIndex}-${summaryIndex}`}
                                                    key={`${section.SectionName}-${summaryIndex}`}
                                                    onInput={async (e) => {
                                                      let arr =
                                                        modifiedJson
                                                          .ClinicalDocumentation
                                                          .Sections;
                                                      const liElement =
                                                        e.currentTarget;
                                                      if (
                                                        liElement?.innerText ==
                                                        "\n"
                                                      ) {
                                                        await addNewLineIndex(
                                                          `summary-${sectionIndex}-${summaryIndex}`
                                                        );
                                                        focusElementById(
                                                          `summary-${sectionIndex}-${
                                                            summaryIndex + 1
                                                          }`
                                                        );
                                                      } else {
                                                        updateJSON(
                                                          `summary-${sectionIndex}-${summaryIndex}`,
                                                          e?.target?.innerText
                                                        );
                                                      }
                                                    }}
                                                    onKeyDown={async (
                                                      event
                                                    ) => {
                                                      const liElement =
                                                        event.currentTarget;
                                                      if (
                                                        event.key === "Enter" &&
                                                        !event.shiftKey
                                                      ) {
                                                        event.preventDefault();
                                                        await addNewLineIndex(
                                                          `summary-${sectionIndex}-${summaryIndex}`
                                                        );
                                                        focusElementById(
                                                          `summary-${sectionIndex}-${
                                                            summaryIndex + 1
                                                          }`
                                                        );
                                                      }
                                                    }}
                                                    suppressContentEditableWarning={
                                                      true
                                                    }
                                                    className="focus:outline-none"
                                                  >
                                                    {item?.SummarizedSegment}
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          );
                                        }
                                      )}
                                                </div>
                                                <br />
                                              </div>
                                            )}
                                          </div>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div></div>
                                </div>
                              </div>
                            )}</div>
    </>
  ) : showAudioFilesData ? (
    <>
      <div>{showAudioFilesData && (
                                <div>
                                  {showClinicalNotes && (
                                    <>
                                      <div className="!bg-[#D9D9D9] flex justify-between items-center bg-gray-100 p-3 border-b border-gray-300 h-[4.7rem]">
                                        <div>
                                          <div className="flex items-center space-x-2">
                                            <h3 className=" text-xl font-bold text-[#A31B1B]">
                                              Audio files
                                            </h3>
                                          </div>
                                        </div>
                                        <div>
                                          <button className="text-gray-600 hover:text-gray-800 text-lg">
                                            <img
                                              id="CloseImageIcon"
                                              onClick={handleCloseAudioFiles}
                                              className={`mb-1 mr-2 inline cursor-pointer`}
                                              src={closeImage}
                                            />
                                          </button>
                                        </div>
                                      </div>
                                      <div className=" grid grid-cols-2 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-1  ">
                                        <div className=" ">
                                        {filteredSelFiles  ? (
  
  (() => {
    const file = filteredSelFiles ;
   

    let ClnicalDocuments;
                                                  const fileExtension = file?.documentUrl
                                                   ? file.documentUrl.split(".").pop().toLowerCase()
                                                   : null;
                                                 
                                                  const isAudio = [
                                                    "mp3",
                                                    "wav",
                                                    "ogg",
                                                    "m4a",
                                                  ].includes(fileExtension);
                                                  const mimeTypes = {
                                                    mp3: "audio/mpeg",
                                                    wav: "audio/wav",
                                                    ogg: "audio/ogg",
                                                    m4a: "audio/mp4",
                                                  };
                                                  if (file?.transcript) {
                                                    const date = new Date(
                                                      file?.creationTime
                                                    );
                                                    const day = String(
                                                      date.getDate()
                                                    ).padStart(2, "0");
                                                    const month = String(
                                                      date.getMonth() + 1
                                                    ).padStart(2, "0");
                                                    const year = String(
                                                      date.getFullYear()
                                                    ).slice(-2);


      return (
        <div className="content-container pt-2 pb-2">
          <ul>
            <li>
              <div>
                <div className="p-3 justify-between pb-[0px] flex">
                <h3 className="m-0 p-0 ">
                                                                  <span className="text-[#A31B1B] font-semibold md:text-[14px] lg:text-[14px] xl:text-[16px]">{`Note 1`}</span>
                                                                  <span className="text-black font-normal ml-2 md:text-[14px] lg:text-[14px] xl:text-[16px]">{` (${new Date(
                                                                    file.creationTime
                                                                  ).toLocaleDateString(
                                                                    "en-US",
                                                                    {
                                                                      month:
                                                                        "short",
                                                                      day: "2-digit",
                                                                      year: "numeric",
                                                                    }
                                                                  )})`}</span>
                                                                  
                                                                </h3>
                                                                {file?.transcript !=
                                                                "Empty" ? (
                                                                  <CustomAudioPlayer
                                                                    key={
                                                                      file.id
                                                                    }
                                                                    id="AudioTagId"
                                                                    fileUrl={`${process.env.REACT_APP_EMRAXIS_S3_URL}/${file?.documentUrl}`}
                                                                    isPlaying={
                                                                      currentPlaying ===
                                                                      file
                                                                    }
                                                                    onPlay={() =>
                                                                      setCurrentPlaying(
                                                                        file
                                                                      )
                                                                    }
                                                                    onPause={() =>
                                                                      setCurrentPlaying(
                                                                        null
                                                                      )
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <p></p>
                                                                )}
                </div>
              </div>
            </li>
            <li className="pl-[0.65rem] pr-[1rem]">
              {file?.transcript !== "Empty" ? (
                <p className="text-base md:text-[14px] lg:text-[14px] xl:text-[16px]">{file?.transcript}</p>
              ) : (
                <p className="text-center px-4 py-2">
                  Voice Notes are not present.
                </p>
              )}
            </li>
          </ul>
        </div>
      );
    } else {
      return (
        <p className="mb-4 text-center border px-4 py-2">
          No audio files available.
        </p>
      );
    }
  })()
) : (
  <p className="mb-4 text-center border px-4 py-2">
    No audio files available.
  </p>
)}
                                         
                                        </div>
                                        <div className="col-span-9 justify-self-start"></div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}</div>
      <div>{selectedNote && (
                              <div className=" border bg-[#FFFFFF] border-gray-300 rounded-lg shadow-md font-sans">
                                {/* Header Section */}
                                <div className="!bg-[#D9D9D9] flex justify-between items-center bg-gray-100 p-3 border-b border-gray-300 h-[4.65rem]">
                                  <div>
                                    <div className="flex items-center space-x-2">
                                      <h3 className=" text-xl font-bold text-[#A31B1B]">
                                        Clinical Notes
                                      </h3>
                                      <p className="text-sm text-gray-600 pt-[3px]">
                                      {appointment.doctor_name}
                                      </p>
                                    </div>
                                   
                                  </div>
                                  <div className="flex space-x-2">
                                  {!ContentEdit.current && (
                                    <div className="flex gap-[4px]">
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="AddImageIcon"
                                       
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="CopyImageIcon"
                                        onClick={(e) => {
                                        copyToClipBord(e);
                                       }}
                                        src={copyImage}
                                      />
                                    </button>
                                    {clinicalNoteVersionList.length > 1 && (
                            <Select
                              labelId="audio-transcipt-clinical-note-version-select"
                              id="audio-transcipt-clinical-note-version-select"
                              value={
                                latestNote ? clinicalNoteVersion : latestVersion
                              }
                              label=""
                              onChange={(e) => {
                                setLatestNote(true);
                                handleVersionPatientDropdown(e?.target?.value);
                              }}
                              size="small"
                              style={{
                                height: 30,
                                backgroundColor: "#fff",
                                marginTop: "3px",
                              }}
                              inputProps={{
                                sx: {
                                  fontWeight: "500",
                                  maxHeight: "50px",
                                },
                                "data-testid":
                                  "audio-transcipt-clinical-note-version-types",
                              }}
                              disabled={!clinicalNoteVersionList}
                            >
                              {clinicalNoteVersionList?.map((version) => (
                                <MenuItem
                                  value={version}
                                  key={version}
                                  id={version}
                                >
                                  {version}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="EditImageIcon"
                                        onClick={(e) => {
                                          handleEditButton(e, selectedNote);
                            }}
                                        src={editImage}
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="DownloadImageIcon"
                                        onClick={() => handleDownloadPDF(transcriptJson?.Sections, appointment.doctor_name,patientName , patient_id, clinicalVersion)}
                                        src={downloadImage}
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="CancelImageIcon"
                                        onClick={handleCloseClinicalNotes}
                                     
                                        src={cancelImage}
                                      />
                                    </button>
                                    </div>
                            )}
                            {ContentEdit.current && (
                        <div className="flex justify-between items-end space-x-2">
                          <div>
                            <button
                            id="btnSaveNote"
                              onClick={handleSaveNoteButton}
                              disabled={
                                modifyClinicalNoteLoading ||
                                 compareJson()
                              }
                              className={
                                isActiveSaveButton
                                  ? "bg-[#b81f1f] text-[#f6f3f3] border-red-700 hover:bg-red-500 w-32 active:bg-white-700 font-medium py-2 px-4 h-100% w-100% rounded transition duration-300 ease-in-out rounded-xl"
                                  : "bg-[#cbc0c0] text-[#8e7979] border-red-700 w-32 active:bg-white-700 font-medium py-2 px-4 h-100% w-100% rounded transition duration-300 ease-in-out rounded-xl"
                              }
                            >
                              Save
                            </button>{" "}
                          </div>
                          <div>
                            <button
                            id="btnCancelEdit"
                              onClick={handleCancelEditButton}
                              className="bg-[#fffefe] text-[#e26666] border-red-700 hover:bg-[#e8d2d2] w-32 font-medium py-2 px-4 rounded transition duration-300 ease-in-out rounded-xl"
                            >
                              Cancel
                            </button>{" "}
                          </div>
                          <div>
                            <button className="text-gray-600 hover:text-gray-800 text-lg">
                              <img
                                id="AudiohandleCloseEditFunctionality"
                                onClick={handleCloseEditFunctionality}
                              
                                src={cancelImage}
                              />
                            </button>
                          </div>
                        </div>
                      )}
                                  </div>
                                </div>

                              

                                {/* Patient Info */}

                                <div
                                  style={{ display: "-webkit-inline-box" }}
                                  
                                >
                                  {!showAudioFilesData && (
                                    <div className="mt-[16rem]">
                                      <button
                                        id="AudioFilesBtn"
                                       onClick={() => {
                                          handleClinicalNotes(
                                            "showClinicalNotes_Id",selectedNote.Id
                                          );
                                        }}
                                      >
                                     
                                        <img
                                          id="AudioEditIcon"
                                          className={`size-auto mb-1 mr-2 inline cursor-pointer text-red-700`}
                                          src={AudioFiles}
                                        />
                                      </button>
                                    </div>
                                  )}

                                  <div id="clinical-notes-content">
                                    <div
                                      className={` ${
                                        !showAudioFilesData
                                          ? "ml-[1rem]"
                                          : "pl-[1rem]"
                                      } `}
                                    >
                                     
                                      <div>
                                        <ul className="list-disc list-inside   mt-2">
                                          <div className=" mb-4">
                                            {isAiscriptEnable && (
                                              <div
                                                id={"showClinicalNotes_Id"}
                                                className=" flex justify-between hidden sm:inline grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2"
                                              >
                                                <div className="col-span-9 justify-self-start ">
                                                  {!ContentEdit.current && audioFiles &&
                                                  audioFiles.length > 0 ? (
                                                    <>
                                                      {audioFiles?.slice(0,1)?.map(
                                                        (file, index) => {
                                                          if (
                                                            file?.listOfClinicalNote !=
                                                            "Empty"
                                                          ) {
                                                            const clinicalDocumentString =
                                                              file?.listOfClinicalNote;
                                                            let clinicalDocumentParsed =
                                                              [];
                                                            let ClnicalDocuments;

                                                            try {
                                                              if (
                                                                clinicalDocumentString !=
                                                                "Empty"
                                                              ) {
                                                                clinicalDocumentParsed =
                                                                  JSON.parse(
                                                                    clinicalDocumentString
                                                                  );
                                                                let data =
                                                                  clinicalDocumentParsed.reduce(
                                                                    (
                                                                      acc,
                                                                      item
                                                                    ) => {
                                                                      acc[
                                                                        item?.Version
                                                                      ] = {
                                                                        Version:
                                                                          item?.Version,
                                                                        EditedBy:
                                                                          item?.EditedBy,
                                                                        Content:
                                                                          item?.Content
                                                                            ? JSON?.parse(
                                                                                JSON.parse(
                                                                                  item?.Content
                                                                                )?.[0]
                                                                                  ?.Content
                                                                              )
                                                                            : {},
                                                                      };

                                                                      return acc;
                                                                    },
                                                                    {}
                                                                  );
                                                                let dataObj =
                                                                  data?.Original;

                                                                ClnicalDocuments =
                                                                  dataObj
                                                                    .Content
                                                                    .ClinicalDocumentation;
                                                              } else {
                                                                ClnicalDocuments =
                                                                  [];
                                                              }
                                                            } catch (error) {
                                                             
                                                            }

                                                            const date =
                                                              new Date(
                                                                file?.creationTime
                                                              );

                                                            const day = String(
                                                              date.getDate()
                                                            ).padStart(2, "0");
                                                            const month =
                                                              String(
                                                                date.getMonth() +
                                                                  1
                                                              ).padStart(
                                                                2,
                                                                "0"
                                                              );
                                                            const year = String(
                                                              date.getFullYear()
                                                            ).slice(-2);
                                                            return (
                                                              <div className="content-container pt-2 pb-2 pr-4">
                                                                <ul>
                                                                  <li className="md:text-[14px] lg:text-[14px] xl:text-[16px]"
                                                                    key={index}
                                                                  >
                                                                    <div className="grid grid-row-10 gap-4 pr-[5rem]">
                                                                     

                                                                      {transcriptJson?.Sections?.map(
                                                                        (
                                                                          section,
                                                                          sectionIndex
                                                                        ) => (
                                                                          <div
                                                                            className="py-2 pr-2"
                                                                            key={`section-${sectionIndex}`}
                                                                          >
                                                                          <span
  className="!font-bold"
  onInput={(e) => {
    e.stopPropagation();
    e.preventDefault();
  }}
  suppressContentEditableWarning={true}
>
  {toTitleCase(section?.SectionName.replaceAll("_", " "))}
</span>
                                                                            
                                                                            <ul
                                                                              className="!list-disc ml-8"
                                                                              id="clinical_note_unordered_list"
                                                                            >
                                                                              {section?.Summary?.filter(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item?.SummarizedSegment
                                                                              )?.map(
                                                                                (
                                                                                  item,
                                                                                  summaryIndex
                                                                                ) => (
                                                                                  <p
                                                                                    key={`${section.SectionName}-${summaryIndex}`}
                                                                                    onInput={(
                                                                                      e
                                                                                    ) => {
                                                                                      const liElement =
                                                                                        e.currentTarget;
                                                                                      if (
                                                                                        !liElement?.textContent
                                                                                      ) {
                                                                                        liElement?.remove();
                                                                                      }
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                      event
                                                                                    ) => {
                                                                                      if (
                                                                                        event.key ===
                                                                                          "Enter" &&
                                                                                        !event.shiftKey
                                                                                      ) {
                                                                                        event.preventDefault();
                                                                                      }
                                                                                    }}
                                                                                    suppressContentEditableWarning={
                                                                                      true
                                                                                    }
                                                                                  
                                                                                    className="relative pl-4 focus"
                                                                                  >
                                                                                    <span className="absolute left-0">
                                                                                      •
                                                                                    </span>
                                                                                    {
                                                                                      item?.SummarizedSegment
                                                                                    }
                                                                                  </p>
                                                                                )
                                                                              )}
                                                                            </ul>
                                                                          </div>
                                                                        )
                                                                      )}
                                                                    </div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            );
                                                          } else {
                                                            return (
                                                              <ul>
                                                                <li
                                                                  key={index}
                                                                  className="mb-4 flex  text-center"
                                                                >
                                                                  <p className="font-bold">
                                                                    {`Clinical Notes.${
                                                                      index + 1
                                                                    }`}{" "}
                                                                  </p>
                                                                </li>
                                                                <li>
                                                                  {" "}
                                                                  <p
                                                                    id={
                                                                      "emptyErrormsgId"
                                                                    }
                                                                    className="mb-4 flex justify-center items-center text-center"
                                                                  >
                                                                    No Clinical
                                                                    Notes are
                                                                    available.
                                                                  </p>
                                                                </li>
                                                              </ul>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </>
                                                  ) : (
                                                   ""
                                                  )}
                                                  {ContentEdit.current &&
                                      originalJsonBeforeEdit?.Sections?.map(
                                        (section, sectionIndex) => {
                                          return (
                                            <div
                                              className="p-2"
                                              key={`section-${sectionIndex}`}
                                            >
                                              <span
                                                className="!font-semibold"
                                                onInput={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  updateJSON(
                                                    `section-${sectionIndex}`,
                                                    e.target.innerText
                                                  );
                                                }}
                                                suppressContentEditableWarning={
                                                  true
                                                }
                                              >
                                                {`${section?.SectionName}`?.replaceAll(
                                                  "_",
                                                  " "
                                                )}
                                              </span>
                                              <ul
                                                className="!list-disc ml-8"
                                                id="clinical_note_unordered_list"
                                              >
                                                {section?.Summary?.filter(
                                                  (item) =>
                                                    item?.SummarizedSegment
                                                )?.map((item, summaryIndex) => (
                                                  <li
                                                    contentEditable={
                                                      ContentEdit.current
                                                    }
                                                    id={`summary-${sectionIndex}-${summaryIndex}`}
                                                    key={`${section.SectionName}-${summaryIndex}`}
                                                    onInput={async (e) => {
                                                      let arr =
                                                        modifiedJson
                                                          .ClinicalDocumentation
                                                          .Sections;
                                                      const liElement =
                                                        e.currentTarget;
                                                      if (
                                                        liElement?.innerText ==
                                                        "\n"
                                                      ) {
                                                        await addNewLineIndex(
                                                          `summary-${sectionIndex}-${summaryIndex}`
                                                        );
                                                        focusElementById(
                                                          `summary-${sectionIndex}-${
                                                            summaryIndex + 1
                                                          }`
                                                        );
                                                      } else {
                                                        updateJSON(
                                                          `summary-${sectionIndex}-${summaryIndex}`,
                                                          e?.target?.innerText
                                                        );
                                                      }
                                                    }}
                                                    onKeyDown={async (
                                                      event
                                                    ) => {
                                                      const liElement =
                                                        event.currentTarget;
                                                      if (
                                                        event.key === "Enter" &&
                                                        !event.shiftKey
                                                      ) {
                                                        event.preventDefault();
                                                        await addNewLineIndex(
                                                          `summary-${sectionIndex}-${summaryIndex}`
                                                        );
                                                        focusElementById(
                                                          `summary-${sectionIndex}-${
                                                            summaryIndex + 1
                                                          }`
                                                        );
                                                      }
                                                    }}
                                                    suppressContentEditableWarning={
                                                      true
                                                    }
                                                    className="focus:outline-none"
                                                  >
                                                    {item?.SummarizedSegment}
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          );
                                        }
                                      )}
                                                </div>
                                                <br />
                                              </div>
                                            )}
                                          </div>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div></div>
                                </div>
                              </div>
                            )}</div>
    </>
  ) : null}
</div>
                          </div>
                        </div>
                      )}
                      </div>
                    )}
                     
                        <textarea
                          ref={textareaRef}
                          id="doctorNotes"
                          readOnly={appointment?.status === "Completed"}
                          rows={
                            isVoice2TextActive &&
                            clinicalNoteData.filter(
                              (a) => a.notes !== "N/A" && a.notes !== ""
                            ).length > 0
                              ? 2
                              : 5
                          }
                          onChange={(evt) => {
                            setDrNotes(evt.target.value);
                            setIsAnyChanges(true);
                            dispatch(setIsGlobalSaveRequired(true));
                          }}
                          className="w-full mt-2 px-2 rounded-md text-sm border border-gray-300 focus:border-gray-500 text-gray-900 bg-white dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400 h-auto overflow-hidden resize-none"
                          value={drNotes}
                          placeholder="Doctor Notes"
                        ></textarea>
                     
                    </div>
                    <div>
                      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2">
                        {files
                          .filter((a) => a.file_type === "Notes")
                          .map((file, index) => {
                            const fileExtension = file.file_path
                              .split(".")
                              .pop()
                              .toLowerCase();
                            const isImage = [
                              "jpg",
                              "jpeg",
                              "png",
                              "gif",
                              "bmp",
                              "webp",
                            ].includes(fileExtension);
                            const isAudio = [
                              "mp3",
                              "wav",
                              "ogg",
                              "m4a",
                            ].includes(fileExtension);
                            const isVideo = [
                              "mp4",
                              "webm",
                              "ogg",
                              "mov",
                            ].includes(fileExtension);

                            return (
                              <div
                                className="relative border rounded-sm"
                                key={"image-notes-" + index}
                              >
                                <a
                                  rel="noopener"
                                  target="_blank"
                                  href={
                                    process.env.REACT_APP_EMRAXIS_S3_URL +
                                    "/" +
                                    file.file_path
                                  }
                                  className=""
                                >
                                  {isImage ? (
                                    <img
                                      className="max-h-[180px] w-auto h-auto object-contain p-5"
                                      src={
                                        process.env.REACT_APP_EMRAXIS_S3_URL +
                                        "/" +
                                        file.file_path
                                      }
                                      alt="Prescription"
                                    />
                                  ) : isAudio ? (
                                    <div className="flex justify-center items-center h-full">
                                      {" "}
                                      <audio
                                        className="w-full max-w-[400px]"
                                        controls
                                      >
                                        <source
                                          src={
                                            process.env
                                              .REACT_APP_EMRAXIS_S3_URL +
                                            "/" +
                                            file.file_path
                                          }
                                          type={`audio/${fileExtension}`}
                                        />
                                        Your browser does not support the audio
                                        element.
                                      </audio>
                                    </div>
                                  ) : isVideo ? (
                                    <video
                                      className="max-h-[180px] w-auto h-auto object-contain p-5"
                                      controls
                                    >
                                      <source
                                        src={
                                          process.env.REACT_APP_EMRAXIS_S3_URL +
                                          "/" +
                                          file.file_path
                                        }
                                        type={`video/${fileExtension}`}
                                      />
                                      Your browser does not support the video
                                      element.
                                    </video>
                                  ) : (
                                    <div className="flex justify-center items-center h-full">
                                      <FileIcon
                                        ext={"." + fileExtension}
                                        className="max-h-[180px] w-auto h-auto object-contain p-5  "
                                      />
                                    </div>
                                  )}
                                </a>
                                <button
                                  id="btnDeleteDrNoteFile"
                                  className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                                  onClick={() => {
                                    setNotesFileDeleteCnfBox(true);
                                    setNotesFileDeleteID([
                                      { ...file, is_active: false },
                                    ]);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="size-6 text-red-600"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                  </svg>
                                </button>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="text-center text-red-600 p-5">
                    Notes not available!
                  </p>
                )}
              </div>
            ) : (
              <>
                {allClinicalData.length > 0  ? (
                  <div className="w-full rounded-lg bg-gray-50">
                    {allClinicalData.map((data, dataIndex) => {
             
             return (
                        <>
                      <div
                        className={
                          "border border-gray-200" +
                          (dataIndex > 2 && !viewMore ? " hidden" : "")
                        }
                        key={"all-notes-" + dataIndex}
                      >
                        <div className="bg-red-300 h-6">
                          <p className="text-center font-semibold">
                            <span className="px-1">
                              {DateFunction.GetDate(data.date)}
                            </span>
                          </p>
                        </div>
                        
                        <div className="px-2 pt-2">{data.notes}</div>
                        <div>
                        {
                          data?.getTranscriptResponses.length > 0 && (
                            <div className="pt-[0.5rem]   bg-gray-100">
                            <div className=" grid-cols-1 grid"
                             
                            >
                      
                              {!selectedNote && !showAudioFilesData && (
                                <table className=" bg-white border rounded-lg shadow-sm">
                                  <thead>
                                    <tr className="bg-red-800 text-white h-[4.65rem]">
                                      <th className="py-3 px-4 text-left font-medium">
                                        Visit/Upload Details
                                      </th>
                                      {selectedNote && !showAudioFilesData && (
                                        <th className="py-3 px-4 text-left font-medium">
                                          Type
                                        </th>
                                      )}
                                      <th className="py-3 px-4 text-left font-medium">
                                        Last Modified by
                                      </th>
                                     
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="py-3 px-4 text-left font-medium">Type</th>
                                      )}
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="py-3 px-4 text-left font-medium">
                                         Action
                                        </th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.getTranscriptResponses.map((note, index) => {
                                      const isModifiedTime = formatToIST(note.LastModifiedDate);
                                      const istTimeString = formatToIST(note.CreationTime);
                                      let data=note?.TypeOfClinicalNotes?note?.TypeOfClinicalNotes:note?.typeOfClinicalNotes
                                      const clinicalNoteatemplate=data!== ""?
                                      data .replace(/_/g, ' ')  
                                      .replace(/\b\w/g, (char) => char.toUpperCase())
                                     :"History & Physical";
                                      return(
                                      <tr
                                        key={index}
                                        className={`border-b last:border-b-0 cursor-pointer ${
              selectedRowIndex === index ? "bg-blue-100" : "hover:bg-gray-100"
            }`}
                                       
                                      >
                                        <td className="py-3 px-4"
                                          onClick={() => {
                                            handleRowClicked(note);
                                            setSelectedRowIndex(index); 
                                          }}>
                                          <span className="text-black font-normal ml-2">
                                        
                                            {`${new Date(
                                              note.CreationTime
                                            ).toLocaleDateString("en-US", {
                                              month: "short",
                                              day: "2-digit",
                                              year: "numeric",
                                            })} `}
                                          </span>
                                          <br />
                                          <span className="text-[#8C8C8C] font-normal ml-2">
                                          {istTimeString}
                                           
                                          </span>
                                        </td>
                                        {selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4">
                                              {note.type}
                                            </td>
                                          )}
                                        <td className="py-3 px-4"
                                          onClick={() => {
                                            handleRowClicked(note);
                                            setSelectedRowIndex(index); 
                                          }}>
                                          {note.LastModifiedBy} <br />
                                          <span className="text-[#8C8C8C] font-normal">
                                          {`${new Date(
                                              note.LastModifiedDate
                                            ).toLocaleDateString("en-US", {
                                              month: "short",
                                              day: "2-digit",
                                              year: "numeric",
                                            })} `}
                                           
                                          {isModifiedTime}
                                            
                                          </span>
                                        </td>
                                        
                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4"
                                            onClick={() => {
                                              handleRowClicked(note);
                                              setSelectedRowIndex(index); 
                                            }}>
                                              {clinicalNoteatemplate}
                                              </td>
                                          )}

                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4"
                                            >
                                              <button
                                               className=" pl-2 text-[#9B1C1C] disabled:text-[#D88C8C]"
                                               onClick={(e)=>{
                                                setDeleteConfirmBox(true);
                                                setPayloadnote(note);
                                               }}
                                               >
                                              <svg
                                    id="BtnClinicalDeleteAppIcon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-6 h-6 "
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                              </button>
                                            </td>
                                          )}
                                      </tr>
                              )})}
                                  </tbody>
                                </table>
                              )}

                              </div>
                      <div className={` bg-[#FFFFFF] ${
                               (selectedNote && data?.getTranscriptResponses.some(r => r.Id === selectedNote.Id)) ? "w-full" : "w-full"
                              }`}>
                            
                             
                              
                             
                              {selectedNote  && (
                                <div >
  {!showAudioFilesData && selectedNote ? (
    <>
   <div className={` bg-[#FFFFFF] ${
                               (selectedNote && data?.getTranscriptResponses.some(r => r.Id === selectedNote.Id)) ? "grid grid-cols-2 !overflow-hidden" : "grid grid-cols-1"
                              }`}>
         <div>
         {!showAudioFilesData &&  selectedNote  &&(
                                <table className="min-w-full bg-white border rounded-lg shadow-sm">
                                  <thead>
                                    <tr className="bg-red-800 text-white h-[4.65rem]">
                                      <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium">
                                        Visit/Upload Details
                                      </th>
                                      {selectedNote && !showAudioFilesData && (
                                        <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium">
                                          Type
                                        </th>
                                      )}
                                      <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium">
                                        Last Modified by
                                      </th>
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium">
                                          Provider
                                        </th>
                                      )}
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium"></th>
                                      )}
                                      {!selectedNote && !showAudioFilesData && (
                                        <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium">
                                         
                                        </th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.getTranscriptResponses.map((note, index) => {
                                      const isModifiedTime = formatToISTHistory(note.LastModifiedDate);
                                      const istTimeString = formatToISTHistory(note.CreationTime);
                                     
                                      return(
                                      <tr
                                       id={index}
                                        key={index}
                                        onClick={() => {
              handleRowClicked(note);
              setSelectedRowIndex(index); 
            }}
                                       
                                        
                                        className={`border-b last:border-b-0 cursor-pointer ${
              selectedRowIndex === index ? "bg-blue-100" : "hover:bg-gray-100"
            }`} 
            
                                      >
                                        <td className="py-3 px-4">
                                          <span className="text-black font-normal ml-2 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                            {`${new Date(
                                              note.CreationTime
                                            ).toLocaleDateString("en-US", {
                                              month: "short",
                                              day: "2-digit",
                                              year: "numeric",
                                            })} `}
                                          </span>
                                          <br />
                                          <span className="text-[#8C8C8C] font-normal ml-2 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                           {istTimeString}
                                          </span>
                                        </td>
                                        {selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                            { note?.templateType}
                                            </td>
                                          )}
                                          <td className="py-3 px-4 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                          {note.LastModifiedBy} <br />
                                          <span className="text-[#8C8C8C] font-normal md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                          {`${new Date(
                                              note.LastModifiedDate
                                            ).toLocaleDateString("en-US", {
                                              month: "short",
                                              day: "2-digit",
                                              year: "numeric",
                                            })} `}
                                           
                                          {isModifiedTime}
                                            
                                          </span>
                                        </td>
                                       
                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                              {note.provider}
                                            </td>
                                          )}
                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                              {note.template}
                                            </td>
                                          )}

                                        {!selectedNote &&
                                          !showAudioFilesData && (
                                            <td className="py-3 px-4">
                                              <button className="text-gray-500 hover:text-gray-700">
                                               
                                              </button>
                                            </td>
                                          )}
                                      </tr>
                              )})}
                                  </tbody>
                                </table>
                              )}
           </div>
  <div>
  {selectedNote && data?.getTranscriptResponses.some(r => r.Id === selectedNote.Id) && (
                              <div className="border bg-[#FFFFFF] border-gray-300 rounded-lg shadow-md font-sans">
                                {/* Header Section */}
                                <div className="!bg-[#D9D9D9] flex justify-between items-center bg-gray-100 p-3 border-b border-gray-300 h-[4.65rem]">
                                  <div>
                                    <div className="flex items-center space-x-2">
                                      <h3 className=" text-xl font-bold text-[#A31B1B]">
                                        Clinical Notes
                                      </h3>
                                      <p className="text-sm text-gray-600 pt-[3px]">
                                      {appointment.doctor_name}
                                      </p>
                                    </div>
                                   
                                  </div>
                                  <div className="flex space-x-2">
                                  {!ContentEdit.current && (
                                    <div className="flex gap-[4px]">
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="AddImageIcon"
                                       
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="CopyImageIcon"
                                        onClick={(e) => {
                                        copyToClipBord(e);
                                       }}
                                        src={copyImage}
                                      />
                                    </button>
                                    {clinicalNoteVersionList.length > 1 && (
                            <Select
                              labelId="audio-transcipt-clinical-note-version-select"
                              id="audio-transcipt-clinical-note-version-select"
                              value={
                                latestNote ? clinicalNoteVersion : latestVersion
                              }
                              label=""
                              onChange={(e) => {
                                setLatestNote(true);
                                handleVersionPatientDropdown(e?.target?.value);
                              }}
                              size="small"
                              style={{
                                height: 30,
                                backgroundColor: "#fff",
                                marginTop: "3px",
                              }}
                              inputProps={{
                                sx: {
                                  fontWeight: "500",
                                  maxHeight: "50px",
                                },
                                "data-testid":
                                  "audio-transcipt-clinical-note-version-types",
                              }}
                              disabled={!clinicalNoteVersionList}
                            >
                              {clinicalNoteVersionList?.map((version) => (
                                <MenuItem
                                  value={version}
                                  key={version}
                                  id={version}
                                >
                                  {version}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="EditImageIcon"
                                        onClick={(e) => {
                              handleEditContentButton(e, selectedNote);
                            }}
                                        src={editImage}
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="DownloadImageIcon"
                                        onClick={() => handleDownloadPDFPatient(transcriptJsonHistory?.Sections,patientName , patient_id,"Original")}
                                        src={downloadImage}
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="CancelImageIcon"
                                        onClick={handleCloseClinicalNotes}
                                     
                                        src={cancelImage}
                                      />
                                    </button>
                                    </div>
                            )}
                            {ContentEdit.current && (
                        <div className="flex justify-between items-end space-x-2">
                          <div>
                            <button
                            id="btnSaveNote"
                              onClick={handlePatientSaveNoteButton}
                              disabled={
                                modifyClinicalNoteLoading ||
                                 compareJson()
                              }
                              className={
                                isActiveSaveButton
                                  ? "bg-[#b81f1f] text-[#f6f3f3] border-red-700 hover:bg-red-500 w-32 active:bg-white-700 font-medium py-2 px-4 h-100% w-100% rounded transition duration-300 ease-in-out rounded-xl"
                                  : "bg-[#cbc0c0] text-[#8e7979] border-red-700 w-32 active:bg-white-700 font-medium py-2 px-4 h-100% w-100% rounded transition duration-300 ease-in-out rounded-xl"
                              }
                            >
                              Save
                            </button>{" "}
                          </div>
                          <div>
                            <button
                            id="btnCancelEdit"
                              onClick={handleCancelEditButton}
                              className="bg-[#fffefe] text-[#e26666] border-red-700 hover:bg-[#e8d2d2] w-32 font-medium py-2 px-4 rounded transition duration-300 ease-in-out rounded-xl"
                            >
                              Cancel
                            </button>{" "}
                          </div>
                          <div>
                            <button className="text-gray-600 hover:text-gray-800 text-lg">
                              <img
                                id="AudiohandleCloseEditFunctionality"
                                onClick={handleCloseEditFunctionality}
                              
                                src={cancelImage}
                              />
                            </button>
                          </div>
                        </div>
                      )}
                                  </div>
                                </div>

                              

                                {/* Patient Info */}

                                <div
                                  style={{ display: "-webkit-inline-box" }}
                                  
                                >
                                  {!showAudioFilesData && (
                                    <div className="mt-[16rem]">
                                      <button
                                        id="AudioFilesBtn"
                                       onClick={() => {
                                        handleClinicalNotesOpen(
                                            "showClinicalNotes_Id",selectedNote.Id
                                          );
                                        }}
                                      >
                                     
                                        <img
                                          id="AudioEditIcon"
                                          className={`size-auto mb-1 mr-2 inline cursor-pointer text-red-700`}
                                          src={AudioFiles}
                                        />
                                      </button>
                                    </div>
                                  )}

                                  <div id="clinical-notes-content">
                                    <div
                                      className={` ${
                                        !showAudioFilesData
                                          ? "ml-[1rem]"
                                          : "pl-[1rem]"
                                      } `}
                                    >
                                     
                                      <div>
                                        <ul className="list-disc list-inside   mt-2">
                                          <div className=" mb-4">
                                            {isAiscriptEnable && (
                                              <div
                                                id={"showClinicalNotes_Id"}
                                                className=" flex justify-between hidden sm:inline grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2"
                                              >
                                                <div className="col-span-9 justify-self-start ">
                                                  {!ContentEdit.current && (
                                                    <div>
                                                 
                                                    <>
                                                      {data?.getTranscriptResponses?.slice(0,1)?.map(
                                                        (file, index) => {
                                                          if (
                                                            file?.ListOfClinicalNote !=
                                                            "Empty"
                                                          ) {
                                                            const clinicalDocumentString =
                                                              file?.ListOfClinicalNote;
                                                            let clinicalDocumentParsed =
                                                              [];
                                                            let ClnicalDocuments;

                                                            try {
                                                              if (
                                                                clinicalDocumentString !=
                                                                "Empty"
                                                              ) {
                                                                clinicalDocumentParsed =
                                                                  JSON.parse(
                                                                    clinicalDocumentString
                                                                  );
                                                                let data =
                                                                  clinicalDocumentParsed.reduce(
                                                                    (
                                                                      acc,
                                                                      item
                                                                    ) => {
                                                                      acc[
                                                                        item?.Version
                                                                      ] = {
                                                                        Version:
                                                                          item?.Version,
                                                                        EditedBy:
                                                                          item?.EditedBy,
                                                                        Content:
                                                                          item?.Content
                                                                            ? JSON?.parse(
                                                                                JSON.parse(
                                                                                  item?.Content
                                                                                )?.[0]
                                                                                  ?.Content
                                                                              )
                                                                            : {},
                                                                      };

                                                                      return acc;
                                                                    },
                                                                    {}
                                                                  );
                                                                let dataObj =
                                                                  data?.Original;

                                                                ClnicalDocuments =
                                                                  dataObj
                                                                    .Content
                                                                    .ClinicalDocumentation;
                                                              } else {
                                                                ClnicalDocuments =
                                                                  [];
                                                              }
                                                            } catch (error) {
                                                             
                                                            }

                                                            const date =
                                                              new Date(
                                                                file?.creationTime
                                                              );

                                                            const day = String(
                                                              date.getDate()
                                                            ).padStart(2, "0");
                                                            const month =
                                                              String(
                                                                date.getMonth() +
                                                                  1
                                                              ).padStart(
                                                                2,
                                                                "0"
                                                              );
                                                            const year = String(
                                                              date.getFullYear()
                                                            ).slice(-2);
                                                            return (
                                                              <div className="content-container pt-2 pb-2 pr-4">
                                                                <ul>
                                                                  <li
                                                                    key={index}
                                                                  >
                                                                    <div className="grid grid-row-10 gap-4 pr-[5rem]">
                                                                      

                                                                      {transcriptJsonHistory?.Sections?.map(
                                                                        (
                                                                          section,
                                                                          sectionIndex
                                                                        ) => (
                                                                          <div
                                                                            className="py-2 pr-2"
                                                                            key={`section-${sectionIndex}`}
                                                                          >
                                                                          <span
  className="!font-bold"
  onInput={(e) => {
    e.stopPropagation();
    e.preventDefault();
  }}
  suppressContentEditableWarning={true}
>
  {toTitleCase(section?.SectionName.replaceAll("_", " "))}
</span>
                                                                            
                                                                            <ul
                                                                              className="!list-disc ml-8"
                                                                              id="clinical_note_unordered_list"
                                                                            >
                                                                              {section?.Summary?.filter(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item?.SummarizedSegment
                                                                              )?.map(
                                                                                (
                                                                                  item,
                                                                                  summaryIndex
                                                                                ) => (
                                                                                  <p
                                                                                    key={`${section.SectionName}-${summaryIndex}`}
                                                                                    onInput={(
                                                                                      e
                                                                                    ) => {
                                                                                      const liElement =
                                                                                        e.currentTarget;
                                                                                      if (
                                                                                        !liElement?.textContent
                                                                                      ) {
                                                                                        liElement?.remove();
                                                                                      }
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                      event
                                                                                    ) => {
                                                                                      if (
                                                                                        event.key ===
                                                                                          "Enter" &&
                                                                                        !event.shiftKey
                                                                                      ) {
                                                                                        event.preventDefault();
                                                                                      }
                                                                                    }}
                                                                                    suppressContentEditableWarning={
                                                                                      true
                                                                                    }
                                                                                   
                                                                                    className="relative pl-4 focus"
                                                                                  >
                                                                                    <span className="absolute left-0">
                                                                                      •
                                                                                    </span>
                                                                                    {
                                                                                      item?.SummarizedSegment
                                                                                    }
                                                                                  </p>
                                                                                )
                                                                              )}
                                                                            </ul>
                                                                          </div>
                                                                        )
                                                                      )}
                                                                    </div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            );
                                                          } else {
                                                            return (
                                                              <ul>
                                                                <li
                                                                  key={index}
                                                                  className="mb-4 flex  text-center"
                                                                >
                                                                  <p className="font-bold">
                                                                    {`Clinical Notes.${
                                                                      index + 1
                                                                    }`}{" "}
                                                                  </p>
                                                                </li>
                                                                <li>
                                                                  {" "}
                                                                  <p
                                                                    id={
                                                                      "emptyErrormsgId"
                                                                    }
                                                                    className="mb-4 flex justify-center items-center text-center"
                                                                  >
                                                                    No Clinical
                                                                    Notes are
                                                                    available.
                                                                  </p>
                                                                </li>
                                                              </ul>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </>
                                                    </div>
                                                 
                                             ) }
                                                  {ContentEdit.current &&
                                      originalJsonBeforeEdit?.Sections?.map(
                                        (section, sectionIndex) => {
                                          return (
                                            <div
                                              className="p-2"
                                              key={`section-${sectionIndex}`}
                                            >
                                              <span
                                                className="!font-semibold"
                                                onInput={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  updateJSON(
                                                    `section-${sectionIndex}`,
                                                    e.target.innerText
                                                  );
                                                }}
                                                suppressContentEditableWarning={
                                                  true
                                                }
                                              >
                                                {`${section?.SectionName}`?.replaceAll(
                                                  "_",
                                                  " "
                                                )}
                                              </span>
                                              <ul
                                                className="!list-disc ml-8"
                                                id="clinical_note_unordered_list"
                                              >
                                                {section?.Summary?.filter(
                                                  (item) =>
                                                    item?.SummarizedSegment
                                                )?.map((item, summaryIndex) => (
                                                  <li
                                                    contentEditable={
                                                      ContentEdit.current
                                                    }
                                                    id={`summary-${sectionIndex}-${summaryIndex}`}
                                                    key={`${section.SectionName}-${summaryIndex}`}
                                                    onInput={async (e) => {
                                                      let arr =
                                                        modifiedJson
                                                          .ClinicalDocumentation
                                                          .Sections;
                                                      const liElement =
                                                        e.currentTarget;
                                                      if (
                                                        liElement?.innerText ==
                                                        "\n"
                                                      ) {
                                                        await addNewLineIndex(
                                                          `summary-${sectionIndex}-${summaryIndex}`
                                                        );
                                                        focusElementById(
                                                          `summary-${sectionIndex}-${
                                                            summaryIndex + 1
                                                          }`
                                                        );
                                                      } else {
                                                        updateJSON(
                                                          `summary-${sectionIndex}-${summaryIndex}`,
                                                          e?.target?.innerText
                                                        );
                                                      }
                                                    }}
                                                    onKeyDown={async (
                                                      event
                                                    ) => {
                                                      const liElement =
                                                        event.currentTarget;
                                                      if (
                                                        event.key === "Enter" &&
                                                        !event.shiftKey
                                                      ) {
                                                        event.preventDefault();
                                                        await addNewLineIndex(
                                                          `summary-${sectionIndex}-${summaryIndex}`
                                                        );
                                                        focusElementById(
                                                          `summary-${sectionIndex}-${
                                                            summaryIndex + 1
                                                          }`
                                                        );
                                                      }
                                                    }}
                                                    suppressContentEditableWarning={
                                                      true
                                                    }
                                                    className="focus:outline-none"
                                                  >
                                                    {item?.SummarizedSegment}
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          );
                                        }
                                      )}
                                                </div>
                                                <br />
                                              </div>
                                            )}
                                          </div>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div></div>
                                </div>
                              </div>
                            )}
  </div>
   </div>
     
    </>
  ) : showAudioFilesData ? (
    <>
    <div className={` bg-[#FFFFFF] ${
                               ( showAudioFilesData && data?.getTranscriptResponses.some(r => r.Id === selectedNote.Id)) ? "grid grid-cols-2" : "grid grid-cols-1"
                              }`}>
      <div> {showAudioFilesData && (
                                <div>
                                {showAudioFilesData && data?.getTranscriptResponses.some(r => r.Id === selectedFileId)  ? (
                                <div>
                                  {showClinicalNotes && (
                                    <>
                                      <div className="!bg-[#D9D9D9] flex justify-between items-center bg-gray-100 p-3 border-b border-gray-300 h-[4.7rem]">
                                        <div>
                                          <div className="flex items-center space-x-2">
                                            <h3 className=" text-xl font-bold text-[#A31B1B]">
                                              Audio files
                                            </h3>
                                          </div>
                                        </div>
                                        <div>
                                          <button className="text-gray-600 hover:text-gray-800 text-lg">
                                            <img
                                              id="CloseImageIcon"
                                              onClick={handleCloseAudioFiles}
                                              className={`mb-1 mr-2 inline cursor-pointer`}
                                              src={closeImage}
                                            />
                                          </button>
                                        </div>
                                      </div>
                                      <div className=" grid grid-cols-2 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-1  ">
                                        <div className=" ">
                                        {filteredFiles  ? (
  
  (() => {
    const file = filteredFiles ;


    let ClnicalDocuments;
                                                  const fileExtension = file?.DocumentUrl
                                                   ? file.DocumentUrl.split(".").pop().toLowerCase()
                                                   : null;
                                                 
                                                  const isAudio = [
                                                    "mp3",
                                                    "wav",
                                                    "ogg",
                                                    "m4a",
                                                  ].includes(fileExtension);
                                                  const mimeTypes = {
                                                    mp3: "audio/mpeg",
                                                    wav: "audio/wav",
                                                    ogg: "audio/ogg",
                                                    m4a: "audio/mp4",
                                                  };
                                                  if (file?.Transcript) {
                                                    const date = new Date(
                                                      file?.CreationTime
                                                    );
                                                    const day = String(
                                                      date.getDate()
                                                    ).padStart(2, "0");
                                                    const month = String(
                                                      date.getMonth() + 1
                                                    ).padStart(2, "0");
                                                    const year = String(
                                                      date.getFullYear()
                                                    ).slice(-2);


      return (
        <div className="content-container pt-2 pb-2">
          <ul>
            <li>
              <div>
                <div className="p-3 justify-between pb-[0px] flex">
                <h3 className="m-0 p-0 ">
                                                                  <span className="text-[#A31B1B] font-semibold md:text-[14px] lg:text-[14px] xl:text-[16px]">{`Note 1`}</span>
                                                                  <span className="text-black font-normal ml-2 md:text-[14px] lg:text-[14px] xl:text-[16px] md:flex xl:contents">{` (${new Date(
                                                                    file.CreationTime
                                                                  ).toLocaleDateString(
                                                                    "en-US",
                                                                    {
                                                                      month:
                                                                        "short",
                                                                      day: "2-digit",
                                                                      year: "numeric",
                                                                    }
                                                                  )})`}</span>
                                                                  
                                                                </h3>
                                                                {file?.Transcript !=
                                                                "Empty" ? (
                                                                  <CustomAudioPlayer
                                                                    key={
                                                                      file.id
                                                                    }
                                                                    id="AudioTagId"
                                                                    fileUrl={`${process.env.REACT_APP_EMRAXIS_S3_URL}/${file?.DocumentUrl}`}
                                                                    isPlaying={
                                                                      currentPlaying ===
                                                                      file
                                                                    }
                                                                    onPlay={() =>
                                                                      setCurrentPlaying(
                                                                        file
                                                                      )
                                                                    }
                                                                    onPause={() =>
                                                                      setCurrentPlaying(
                                                                        null
                                                                      )
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <p></p>
                                                                )}
                </div>
              </div>
            </li>
            <li className="pl-[0.65rem] pr-[1rem]">
              {file?.Transcript !== "Empty" ? (
                <p className="text-base md:text-[14px] lg:text-[14px] xl:text-[16px]">{file?.Transcript}</p>
              ) : (
                <p className="text-center px-4 py-2">
                  Voice Notes are not present.
                </p>
              )}
            </li>
          </ul>
        </div>
      );
    } else {
      return (
        <p className="mb-4 text-center border px-4 py-2">
          No audio files available.
        </p>
      );
    }
  })()
) : (
  <p className="mb-4 text-center border px-4 py-2">
    No audio files available.
  </p>
)}
                                        
                                          
                                        </div>
                                        <div className="col-span-9 justify-self-start"></div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              ) : (<div>{selectedNote && showAudioFilesData && data?.getTranscriptResponses.some(r => r.Id !== selectedFileId)   && (
                                <div><table className="min-w-full bg-white border rounded-lg shadow-sm">
                                  <thead>
                                    <tr className="bg-red-800 text-white h-[4.65rem]">
                                      <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium">
                                        Visit/Upload Details
                                      </th>
                                      {selectedNote  && (
                                        <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium">
                                          Type
                                        </th>
                                      )}
                                      <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium">
                                        Last Modified by
                                      </th>
                                      
                                      {!selectedNote  && (
                                        <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium">Type</th>
                                      )}
                                      {!selectedNote  && (
                                        <th className="md:text-[14px] lg:text-[14px] xl:text-[16px] py-3 px-4 text-left font-medium">
                                        
                                        </th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.getTranscriptResponses.map((note, index) => {
                                    
                                      const isModifiedTime = formatToISTHistory(note.LastModifiedDate);
                                      const istTimeString = formatToISTHistory(note.CreationTime);
                                      const noteId = `note-${dataIndex}-${index}`;
                                      const clinicalNoteatemplate= note?.typeOfClinicalNotes !== ""?
                                      note?.typeOfClinicalNotes .replace(/_/g, ' ')  
                                      .replace(/\b\w/g, (char) => char.toUpperCase())
                                     :"History & Physical";
                                      return(
                                        <React.Fragment key={noteId}>
                                        
                                      <tr 
                                        id={index}
                                        key={index}
                                        onClick={() => {
              handleRowClicked(note);
              setSelectedRowIndex(index); 
            }}
                                       
                                        
                                        className={`border-b last:border-b-0 cursor-pointer ${
              selectedRowIndex === index ? "bg-blue-100" : "hover:bg-gray-100"
            }`}
                                        
                                      >
                                        <td className="py-3 px-4">
                                          <span className="text-black font-normal ml-2 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                        
                                            {`${new Date(
                                              note.CreationTime
                                            ).toLocaleDateString("en-US", {
                                              month: "short",
                                              day: "2-digit",
                                              year: "numeric",
                                            })} `}
                                          </span>
                                          <br />
                                          <span className="text-[#8C8C8C] font-normal ml-2 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                          {istTimeString}
                                           
                                          </span>
                                        </td>
                                        {selectedNote  && (
                                            <td className="py-3 px-4">
                                              {clinicalNoteatemplate}
s                                              </td>
                                          )}
                                        <td className="py-3 px-4 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                          {note.LastModifiedBy} <br />
                                          <span className="text-[#8C8C8C] font-normal md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                          {`${new Date(
                                              note.LastModifiedDate
                                            ).toLocaleDateString("en-US", {
                                              month: "short",
                                              day: "2-digit",
                                              year: "numeric",
                                            })} `}
                                           
                                          {isModifiedTime}
                                            
                                          </span>
                                        </td>
                                        {!selectedNote  && (
                                            <td className="py-3 px-4 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                              {appointment.doctor_name}
                                            </td>
                                          )}
                                        {!selectedNote  && ( 
                                            <td className="py-3 px-4 md:text-[14px] lg:text-[14px] xl:text-[16px]">
                                              { note?.templateType}
                                            </td>
                                         )}

                                        {!selectedNote  && (
                                            <td className="py-3 px-4 ">
                                              <button className="text-gray-500 hover:text-gray-700">
                                               
                                              </button>
                                            </td>
                                          )}
                                      </tr>
                                      </React.Fragment>
                              )})}
                                  </tbody>
                                </table></div>
                              )}
                                </div>
                                )}</div>)}
      </div>
      <div>
      <div>  {selectedNote && data?.getTranscriptResponses.some(r => r.Id === selectedNote.Id) && (
                              <div className="border bg-[#FFFFFF] border-gray-300 rounded-lg shadow-md font-sans">
                                {/* Header Section */}
                                <div className="!bg-[#D9D9D9] flex justify-between items-center bg-gray-100 p-3 border-b border-gray-300 h-[4.65rem]">
                                  <div>
                                    <div className="flex items-center space-x-2">
                                      <h3 className=" text-xl font-bold text-[#A31B1B]">
                                        Clinical Notes
                                      </h3>
                                      <p className="text-sm text-gray-600 pt-[3px]">
                                      {appointment.doctor_name}
                                      </p>
                                    </div>
                                   
                                  </div>
                                  <div className="flex space-x-2">
                                  {!ContentEdit.current && (
                                    <div className="flex gap-[4px]">
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="AddImageIcon"
                                       
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="CopyImageIcon"
                                        onClick={(e) => {
                                        copyToClipBord(e);
                                       }}
                                        src={copyImage}
                                      />
                                    </button>
                                    {clinicalNoteVersionList.length > 1 && (
                            <Select
                              labelId="audio-transcipt-clinical-note-version-select"
                              id="audio-transcipt-clinical-note-version-select"
                              value={
                                latestNote ? clinicalNoteVersion : latestVersion
                              }
                              label=""
                              onChange={(e) => {
                                setLatestNote(true);
                                handleVersionPatientDropdown(e?.target?.value);
                              }}
                              size="small"
                              style={{
                                height: 30,
                                backgroundColor: "#fff",
                                marginTop: "3px",
                              }}
                              inputProps={{
                                sx: {
                                  fontWeight: "500",
                                  maxHeight: "50px",
                                },
                                "data-testid":
                                  "audio-transcipt-clinical-note-version-types",
                              }}
                              disabled={!clinicalNoteVersionList}
                            >
                              {clinicalNoteVersionList?.map((version) => (
                                <MenuItem
                                  value={version}
                                  key={version}
                                  id={version}
                                >
                                  {version}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="EditImageIcon"
                                        onClick={(e) => {
                              handleEditContentButton(e, selectedNote);
                            }}
                                        src={editImage}
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="DownloadImageIcon"
                                        onClick={() => handleDownloadPDFPatient(transcriptJsonHistory?.Sections, patientName , patient_id,"Original",)}
                                        src={downloadImage}
                                      />
                                    </button>
                                    <button className="text-gray-600 hover:text-gray-800 text-lg">
                                      <img
                                        id="CancelImageIcon"
                                        onClick={handleCloseClinicalNotes}
                                     
                                        src={cancelImage}
                                      />
                                    </button>
                                    </div>
                            )}
                            {ContentEdit.current && (
                        <div className="flex justify-between items-end space-x-2">
                          <div>
                            <button
                            id="btnSaveNote"
                              onClick={handlePatientSaveNoteButton}
                              disabled={
                                modifyClinicalNoteLoading ||
                                 compareJson()
                              }
                              className={
                                isActiveSaveButton
                                  ? "bg-[#b81f1f] text-[#f6f3f3] border-red-700 hover:bg-red-500 w-32 active:bg-white-700 font-medium py-2 px-4 h-100% w-100% rounded transition duration-300 ease-in-out rounded-xl"
                                  : "bg-[#cbc0c0] text-[#8e7979] border-red-700 w-32 active:bg-white-700 font-medium py-2 px-4 h-100% w-100% rounded transition duration-300 ease-in-out rounded-xl"
                              }
                            >
                              Save
                            </button>{" "}
                          </div>
                          <div>
                            <button
                            id="btnCancelEdit"
                              onClick={handleCancelEditButton}
                              className="bg-[#fffefe] text-[#e26666] border-red-700 hover:bg-[#e8d2d2] w-32 font-medium py-2 px-4 rounded transition duration-300 ease-in-out rounded-xl"
                            >
                              Cancel
                            </button>{" "}
                          </div>
                          <div>
                            <button className="text-gray-600 hover:text-gray-800 text-lg">
                              <img
                                id="AudiohandleCloseEditFunctionality"
                                onClick={handleCloseEditFunctionality}
                              
                                src={cancelImage}
                              />
                            </button>
                          </div>
                        </div>
                      )}
                                  </div>
                                </div>

                              

                                {/* Patient Info */}

                                <div
                                  style={{ display: "-webkit-inline-box" }}
                                  
                                >
                                  {!showAudioFilesData && (
                                    <div className="mt-[16rem]">
                                      <button
                                        id="AudioFilesBtn"
                                       onClick={() => {
                                        handleClinicalNotesOpen(
                                            "showClinicalNotes_Id",selectedNote.Id
                                          );
                                        }}
                                      >
                                     
                                        <img
                                          id="AudioEditIcon"
                                          className={`size-auto mb-1 mr-2 inline cursor-pointer text-red-700`}
                                          src={AudioFiles}
                                        />
                                      </button>
                                    </div>
                                  )}

                                  <div id="clinical-notes-content">
                                    <div
                                      className={` ${
                                        !showAudioFilesData
                                          ? "ml-[1rem]"
                                          : "pl-[1rem]"
                                      } `}
                                    >
                                     
                                      <div>
                                        <ul className="list-disc list-inside   mt-2">
                                          <div className=" mb-4">
                                            {isAiscriptEnable && (
                                              <div
                                                id={"showClinicalNotes_Id"}
                                                className=" flex justify-between hidden sm:inline grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2"
                                              >
                                                <div className="col-span-9 justify-self-start ">
                                                  {!ContentEdit.current && (
                                                    <div>
                                                  {/* data?.getTranscriptResponses.length > 0 ? ( */}
                                                    <>
                                                      {data?.getTranscriptResponses?.slice(0,1)?.map(
                                                        (file, index) => {
                                                          if (
                                                            file?.ListOfClinicalNote !=
                                                            "Empty"
                                                          ) {
                                                            const clinicalDocumentString =
                                                              file?.ListOfClinicalNote;
                                                            let clinicalDocumentParsed =
                                                              [];
                                                            let ClnicalDocuments;

                                                            try {
                                                              if (
                                                                clinicalDocumentString !=
                                                                "Empty"
                                                              ) {
                                                                clinicalDocumentParsed =
                                                                  JSON.parse(
                                                                    clinicalDocumentString
                                                                  );
                                                                let data =
                                                                  clinicalDocumentParsed.reduce(
                                                                    (
                                                                      acc,
                                                                      item
                                                                    ) => {
                                                                      acc[
                                                                        item?.Version
                                                                      ] = {
                                                                        Version:
                                                                          item?.Version,
                                                                        EditedBy:
                                                                          item?.EditedBy,
                                                                        Content:
                                                                          item?.Content
                                                                            ? JSON?.parse(
                                                                                JSON.parse(
                                                                                  item?.Content
                                                                                )?.[0]
                                                                                  ?.Content
                                                                              )
                                                                            : {},
                                                                      };

                                                                      return acc;
                                                                    },
                                                                    {}
                                                                  );
                                                                let dataObj =
                                                                  data?.Original;

                                                                ClnicalDocuments =
                                                                  dataObj
                                                                    .Content
                                                                    .ClinicalDocumentation;
                                                              } else {
                                                                ClnicalDocuments =
                                                                  [];
                                                              }
                                                            } catch (error) {
                                                             
                                                            }

                                                            const date =
                                                              new Date(
                                                                file?.creationTime
                                                              );

                                                            const day = String(
                                                              date.getDate()
                                                            ).padStart(2, "0");
                                                            const month =
                                                              String(
                                                                date.getMonth() +
                                                                  1
                                                              ).padStart(
                                                                2,
                                                                "0"
                                                              );
                                                            const year = String(
                                                              date.getFullYear()
                                                            ).slice(-2);
                                                            return (
                                                              <div className="content-container pt-2 pb-2 pr-4">
                                                                <ul>
                                                                  <li className="md:text-[14px] lg:text-[14px] xl:text-[16px]"
                                                                    key={index}
                                                                  >
                                                                    <div className="grid grid-row-10 gap-4 pr-[5rem]">
                                                                      

                                                                      {transcriptJsonHistory?.Sections?.map(
                                                                        (
                                                                          section,
                                                                          sectionIndex
                                                                        ) => (
                                                                          <div
                                                                            className="py-2 pr-2"
                                                                            key={`section-${sectionIndex}`}
                                                                          >
                                                                          <span
  className="!font-bold"
  onInput={(e) => {
    e.stopPropagation();
    e.preventDefault();
  }}
  suppressContentEditableWarning={true}
>
  {toTitleCase(section?.SectionName.replaceAll("_", " "))}
</span>
                                                                            
                                                                            <ul
                                                                              className="!list-disc ml-8"
                                                                              id="clinical_note_unordered_list"
                                                                            >
                                                                              {section?.Summary?.filter(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item?.SummarizedSegment
                                                                              )?.map(
                                                                                (
                                                                                  item,
                                                                                  summaryIndex
                                                                                ) => (
                                                                                  <p
                                                                                    key={`${section.SectionName}-${summaryIndex}`}
                                                                                    onInput={(
                                                                                      e
                                                                                    ) => {
                                                                                      const liElement =
                                                                                        e.currentTarget;
                                                                                      if (
                                                                                        !liElement?.textContent
                                                                                      ) {
                                                                                        liElement?.remove();
                                                                                      }
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                      event
                                                                                    ) => {
                                                                                      if (
                                                                                        event.key ===
                                                                                          "Enter" &&
                                                                                        !event.shiftKey
                                                                                      ) {
                                                                                        event.preventDefault();
                                                                                      }
                                                                                    }}
                                                                                    suppressContentEditableWarning={
                                                                                      true
                                                                                    }
                                                                                  
                                                                                    className="relative pl-4 focus"
                                                                                  >
                                                                                    <span className="absolute left-0">
                                                                                      •
                                                                                    </span>
                                                                                    {
                                                                                      item?.SummarizedSegment
                                                                                    }
                                                                                  </p>
                                                                                )
                                                                              )}
                                                                            </ul>
                                                                          </div>
                                                                        )
                                                                      )}
                                                                    </div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            );
                                                          } else {
                                                            return (
                                                              <ul>
                                                                <li
                                                                  key={index}
                                                                  className="mb-4 flex  text-center"
                                                                >
                                                                  <p className="font-bold">
                                                                    {`Clinical Notes.${
                                                                      index + 1
                                                                    }`}{" "}
                                                                  </p>
                                                                </li>
                                                                <li>
                                                                  {" "}
                                                                  <p
                                                                    id={
                                                                      "emptyErrormsgId"
                                                                    }
                                                                    className="mb-4 flex justify-center items-center text-center"
                                                                  >
                                                                    No Clinical
                                                                    Notes are
                                                                    available.
                                                                  </p>
                                                                </li>
                                                              </ul>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </>
                                                    </div>
                                                 
                                             ) }
                                                  {ContentEdit.current &&
                                      originalJsonBeforeEdit?.Sections?.map(
                                        (section, sectionIndex) => {
                                          return (
                                            <div
                                              className="p-2"
                                              key={`section-${sectionIndex}`}
                                            >
                                              <span
                                                className="!font-semibold"
                                                onInput={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  updateJSON(
                                                    `section-${sectionIndex}`,
                                                    e.target.innerText
                                                  );
                                                }}
                                                suppressContentEditableWarning={
                                                  true
                                                }
                                              >
                                                {`${section?.SectionName}`?.replaceAll(
                                                  "_",
                                                  " "
                                                )}
                                              </span>
                                              <ul
                                                className="!list-disc ml-8"
                                                id="clinical_note_unordered_list"
                                              >
                                                {section?.Summary?.filter(
                                                  (item) =>
                                                    item?.SummarizedSegment
                                                )?.map((item, summaryIndex) => (
                                                  <li
                                                    contentEditable={
                                                      ContentEdit.current
                                                    }
                                                    id={`summary-${sectionIndex}-${summaryIndex}`}
                                                    key={`${section.SectionName}-${summaryIndex}`}
                                                    onInput={async (e) => {
                                                      let arr =
                                                        modifiedJson
                                                          .ClinicalDocumentation
                                                          .Sections;
                                                      const liElement =
                                                        e.currentTarget;
                                                      if (
                                                        liElement?.innerText ==
                                                        "\n"
                                                      ) {
                                                        await addNewLineIndex(
                                                          `summary-${sectionIndex}-${summaryIndex}`
                                                        );
                                                        focusElementById(
                                                          `summary-${sectionIndex}-${
                                                            summaryIndex + 1
                                                          }`
                                                        );
                                                      } else {
                                                        updateJSON(
                                                          `summary-${sectionIndex}-${summaryIndex}`,
                                                          e?.target?.innerText
                                                        );
                                                      }
                                                    }}
                                                    onKeyDown={async (
                                                      event
                                                    ) => {
                                                      const liElement =
                                                        event.currentTarget;
                                                      if (
                                                        event.key === "Enter" &&
                                                        !event.shiftKey
                                                      ) {
                                                        event.preventDefault();
                                                        await addNewLineIndex(
                                                          `summary-${sectionIndex}-${summaryIndex}`
                                                        );
                                                        focusElementById(
                                                          `summary-${sectionIndex}-${
                                                            summaryIndex + 1
                                                          }`
                                                        );
                                                      }
                                                    }}
                                                    suppressContentEditableWarning={
                                                      true
                                                    }
                                                    className="focus:outline-none"
                                                  >
                                                    {item?.SummarizedSegment}
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          );
                                        }
                                      )}
                                                </div>
                                                <br />
                                              </div>
                                            )}
                                          </div>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div></div>
                                </div>
                              </div>
                            )}
                            </div>
      </div>
    </div>
      
      
    </>
  ) : null}
</div>   
                              )

                              }
                             
</div>
                         
                         
                        </div>
                          )
                        }
                       
                        </div>  
                      </div>
                     
                        
                        {data?.audio_notes?.filter(
                          (a) => a.audio_notes !== "N/A" && a.audio_notes !== ""
                        ).length > 0 && (
                          <div className="p-2">
                            {data?.audio_notes
                              ?.filter(
                                (a) =>
                                  a.audio_notes !== "N/A" &&
                                  a.audio_notes !== ""
                              )
                              .map((cn, ind) => (
                                <div
                                  key={"cn-" + ind}
                                  className="flex justify-between my-1 "
                                >
                                  <div>
                                    <p className="break-words">
                                      <span className="font-semibold">
                                        Note {ind + 1}:{" "}
                                      </span>{" "}
                                      {cn.audio_notes}
                                    </p>
                                  </div>
                                  <div>
                                    {cn.audio_file && (
                                      <EMRAudioPlayer
                                        source={
                                          process.env.REACT_APP_EMRAXIS_S3_URL +
                                          "/" +
                                          cn.audio_file
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 p-2">
                          {data?.files?.map((file, index) => {
                            const fileExtension = file.upload_file
                              .split(".")
                              .pop()
                              .toLowerCase();
                            const isImage = [
                              "jpg",
                              "jpeg",
                              "png",
                              "gif",
                              "bmp",
                              "webp",
                            ].includes(fileExtension);
                            const isAudio = [
                              "mp3",
                              "wav",
                              "ogg",
                              "m4a",
                            ].includes(fileExtension);
                            const isVideo = [
                              "mp4",
                              "webm",
                              "ogg",
                              "mov",
                            ].includes(fileExtension);

                            return (
                              <div
                                className="relative border rounded-sm"
                                key={"image-notes-" + index}
                              >
                                <a
                                  rel="noopener"
                                  target="_blank"
                                  href={
                                    process.env.REACT_APP_EMRAXIS_S3_URL +
                                    "/" +
                                    file.upload_file
                                  }
                                  className=""
                                >
                                  {isImage ? (
                                    <img
                                      id={"imgDoctorNotesFile" + index}
                                      className="max-h-[180px] w-auto h-auto object-contain p-5"
                                      src={
                                        process.env.REACT_APP_EMRAXIS_S3_URL +
                                        "/" +
                                        file.upload_file
                                      }
                                      alt="Doctor Notes"
                                    />
                                  ) : isAudio ? (
                                    <div className="flex justify-center items-center h-full">
                                      {" "}
                                      <audio
                                        id={"audioDoctorNotesFile" + index}
                                        className="w-full max-w-[400px]"
                                        controls
                                      >
                                        <source
                                          src={
                                            process.env
                                              .REACT_APP_EMRAXIS_S3_URL +
                                            "/" +
                                            file.upload_file
                                          }
                                          type={`audio/${fileExtension}`}
                                        />
                                        Your browser does not support the audio
                                        element.
                                      </audio>
                                    </div>
                                  ) : isVideo ? (
                                    <video
                                      id={"videogDoctorNotesFile" + index}
                                      className="max-h-[180px] w-auto h-auto object-contain p-5"
                                      controls
                                    >
                                      <source
                                        src={
                                          process.env.REACT_APP_EMRAXIS_S3_URL +
                                          "/" +
                                          file.upload_file
                                        }
                                        type={`video/${fileExtension}`}
                                      />
                                      Your browser does not support the video
                                      element.
                                    </video>
                                  ) : (
                                    <div className="flex justify-center items-center h-full">
                                      <FileIcon
                                        id={"fileDoctorNotesFile" + index}
                                        ext={"." + fileExtension}
                                        className="max-h-[180px] w-auto h-auto object-contain p-5  "
                                      />
                                    </div>
                                  )}
                                </a>
                              </div>
                            );
                          })}
                        </div>
                     </> 
                )})}
                   
                    <div className="flex justify-end">
                      {allClinicalData.length > 3 && (
                        <button
                          id="btnViewMoreDrNotes"
                          className="text-red-600 p-2"
                          onClick={() => {
                            setViewMore((vm) => !vm);
                          }}
                        >
                          {viewMore ? "View Less" : "View More"}
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {dataLoading && (
                      <p className="text-center p-5">
                        <svg
                          id="imgDrNotesDataLoading"
                          aria-hidden="true"
                          role="status"
                          className="size-6 ml-2 text-red-600 animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      </p>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DoctorNotes;
