import React, { useState } from 'react'
import { Tooltip } from "@mui/material";

const MonthViewAppointment = ({ day, handleAppointmentDetailPopup, events }) => {
    const [showAll, setShowAll] = useState(false);
    const renderEvent = (date) => {
      const event = events.filter((event) => event.currentDate === date);
      const formatDateTime = (dateStr, hour, minute) => {
        // Convert `DDMMYYYY` to `YYYY-MM-DD`
        const formattedDateStr = `${dateStr.slice(4)}-${dateStr.slice(2, 4)}-${dateStr.slice(0, 2)}`;
        const dateTimeStr = `${formattedDateStr}T${hour.padStart(2, "0")}:${minute.padStart(2, "0")}:00`;
     // Format to 12-hour format
        const dateObj = new Date(dateTimeStr);
        if (isNaN(dateObj)) {
          return "Invalid Date";
        }
    
        const options = {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        };
    
        return new Intl.DateTimeFormat("en-US", options).format(dateObj);
      };
    
      return event.length > 0 ? (
        <>
          {event.slice(0, showAll ? event.length : 3).map((ev, i) => {
            const formattedDateTime = formatDateTime(ev.currentDate, ev.hour, ev.minute);
            const fullName = `${ev.appointment.FirstName} ${ev.appointment.MiddleName?.charAt(0) || ""} ${ev.appointment.LastName}`;
            const doctorName = ev.appointment.doctor_name.trim();
    
            return (
              <Tooltip
                key={"TooltipAgantaAppointmentMonthView" + i}
                title={
                  <>
                    <div>{fullName} - {doctorName}</div>
                    <div>{formattedDateTime}</div>
                  </>
                }
                placement="top-start"
                arrow
              >
                <div
                  key={"DivAgantaAppointmentMonthView" + i}
                  id={"DivAgantaAppointmentMonthView" + i}
                  onClick={(evt) => handleAppointmentDetailPopup(evt, ev.appointment)}
                  className="bg-[#004F59] text-[#454242] truncate p-1 mb-2 rounded text-sm cursor-pointer hover:bg-[#004F59]"
                >
                  {fullName}
                </div>
              </Tooltip>
            );
          })}
          {event.length > 3 && (
            <div className="text-center bg-gray-200">
              <button
                id={"BtnAgantaAppointmentMonthViewHideShow"}
                className="text-blue-500 text-sm hover:underline"
                onClick={() => setShowAll((o) => !o)}
              >
                {showAll ? "Show Less" : "+" + (event.length - 3) + " more"}
              </button>
            </div>
          )}
        </>
      ) : null;
    };
    return (
      <>{renderEvent(day)}</>
    ) 
}

export default MonthViewAppointment