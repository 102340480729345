import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  notification: {action: false, message: null,status:'success', autoHideDuration: 5000,}}

export const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        setNotification: (state, action) => {
            state.notification = action.payload
        },

    },
})

// Action creators are generated for each case reducer function
export const { setNotification } = toastSlice.actions

export default toastSlice.reducer