import { defaultTheme } from "react-select"

const theme = [
     {
        name: "defaultTheme",
        tableHeaderBg: 'bg-[#005F74]',
        tableHeaderText:'text-[#ECF0FF]',
        headingTitleColor: 'text-[#004F59]',
        popupCloseBtnColor: 'text-red-800',
        borderTheame:'border-red-800',
        popupCloseBtnHoverColor: 'text-red-900',
        saveBtnColor: 'text-white',
        saveBtnBgColor: 'bg-[#004F59]',
        saveBtnHoverColor: 'bg-[#3D3868]',
        cancelBtnColor: 'text-white',
        cancelBtnBgColor: 'bg-[#6B7280]',
        cancelBtnHoverColor: 'bg-gray-700',
        addBtnColor: 'text-white',
        addBtnBgColor: 'bg-[#23c3BA]',
        addBtnHoverColor: 'bg-[#0469A1]',
        editBtnColor: 'text-white',
        deleteColor: 'text-red-700',
        deleteDisableColor: 'text-red-300',
        reloadColor:'text-red-700',
        reloadHoverColor:'text-red-800',
        editColor:'text-red-700',
        editDisableColor:'text-red-300',
        editBtnBgColor: 'bg-red-500',
        editBtnHoverColor: 'bg-red-700',
        otherBtnColor: 'text-white',
        otherBtnBgColor: 'bg-green-500',
        otherBtnHoverColor: 'bg-green-700',
        profileNavtextHover:'hover:text-white',
        profileNavbgHover:'hover:bg-[#004F59]',
        profileNavActivebg:'bg-[#004F59]',
        profileActiveText:'text-white',
        navbarColor: 'text-white',
        navbarTextColor: 'text-black',
        navbarBgHoverColor: 'bg-white',
        navbarActiveColor: 'text-[#E7714C]',
        navbarActiveColorSuperAdmin:'text-[#A31B1B]',
        navbarBgColor:'bg-white',
        navbarSuperAdminBgColor:'#FFFFFF'
    },
     {
        name: "theme1",
        headingTitleColor: 'text-violet-600',
        popupCloseBtnColor: 'text-violet-500',
        popupCloseBtnHoverColor: 'text-violet-800',
        borderTheame:'border-violet-800',
        saveBtnColor: 'text-white',
        saveBtnBgColor: 'bg-violet-500',
        saveBtnHoverColor: 'bg-violet-800',
        cancelBtnColor: 'text-white',
        cancelBtnBgColor: 'bg-gray-500',
        cancelBtnHoverColor: 'bg-gray-700',
        addBtnColor: 'text-white',
        addBtnBgColor: 'bg-violet-700',
        addBtnHoverColor: 'bg-violet-800',
        deleteColor: 'text-violet-700',
        deleteDisableColor: 'text-violet-300',
        reloadColor:'text-violet-700',
        reloadHoverColor:'text-violet-800',
        editBtnColor: 'text-white',
        editColor:'text-violet-700',
        editDisableColor:'text-violet-300',
        editBtnBgColor: 'bg-violet-500',
        editBtnHoverColor: 'bg-violet-700',
        otherBtnColor: 'text-white',
        otherBtnBgColor: 'bg-green-500',
        otherBtnHoverColor: 'bg-green-700',
        navbarColor: 'text-black',
        navbarTextColor: 'text-violet-500',
        navbarBgHoverColor: 'bg-violet-200',
        navbarActiveColor: 'text-violet-100',
        navbarBgColor:'bg-violet-300'
    },
    {
        name: "theme2",
        headingTitleColor: 'text-blue-800',
        popupCloseBtnColor: 'text-blue-500',
        popupCloseBtnHoverColor: 'text-blue-800',
        borderTheame:'border-blue-800',
        saveBtnColor: 'text-white',
        saveBtnBgColor: 'bg-blue-500',
        saveBtnHoverColor: 'bg-blue-800',
        cancelBtnColor: 'text-white',
        cancelBtnBgColor: 'bg-red-500',
        cancelBtnHoverColor: 'bg-red-700',
        addBtnColor: 'text-white',
        addBtnBgColor: 'bg-blue-700',
        addBtnHoverColor: 'bg-blue-800',
        deleteColor: 'text-blue-700',
        reloadColor:'text-blue-700',
        reloadHoverColor:'text-blue-800',
        deleteDisableColor: 'text-blue-300',
        editBtnColor: 'text-white',
        editColor:'text-blue-700',
        editDisableColor:'text-blue-300',
        editBtnBgColor: 'bg-blue-500',
        editBtnHoverColor: 'bg-blue-700',
        otherBtnColor: 'text-white',
        otherBtnBgColor: 'bg-green-500',
        otherBtnHoverColor: 'bg-green-700',
        navbarColor: 'text-black',
        navbarTextColor: 'text-blue-600',
        navbarBgHoverColor: 'bg-blue-300',
        navbarActiveColor: 'text-blue-200',
        navbarBgColor:'bg-blue-300'
    },
     {
        name: "theme3",
        headingTitleColor: 'text-green-800',
        popupCloseBtnColor: 'text-green-500',
        popupCloseBtnHoverColor: 'text-green-800',
        borderTheame:'border-green-800',
        saveBtnColor: 'text-white',
        saveBtnBgColor: 'bg-green-500',
        saveBtnHoverColor: 'bg-green-800',
        cancelBtnColor: 'text-white',
        cancelBtnBgColor: 'bg-gray-500',
        cancelBtnHoverColor: 'bg-gray-700',
        addBtnColor: 'text-white',
        addBtnBgColor: 'bg-green-700',
        addBtnHoverColor: 'bg-green-800',
        deleteColor: 'text-green-700',
        deleteDisableColor: 'text-green-300',
        reloadColor:'text-green-700',
        reloadHoverColor:'text-green-800',
        editBtnColor: 'text-white',
        editColor:'text-green-700',
        editDisableColor:'text-green-300',
        editBtnBgColor: 'bg-green-500',
        editBtnHoverColor: 'bg-green-700',
        otherBtnColor: 'text-white',
        otherBtnBgColor: 'bg-red-500',
        otherBtnHoverColor: 'bg-red-700',
        navbarColor: 'text-black',
        navbarTextColor: 'text-green-600',
        navbarBgHoverColor: 'bg-green-300',
        navbarActiveColor: 'text-green-200',
        navbarBgColor:'bg-green-300'
    }



]
const font_family = [
    {
        name: "font1",
        fontFamily: 'Arial, sans-serif'
    },
    {
        name: "font2",
        fontFamily: 'Times New Roman, serif'
    },
    {
        name: "font3",
        fontFamily: 'Verdana, sans-serif'
    },
    {
        name: "font4",
        fontFamily: 'Courier New, monospace'
    }
];

export { theme, font_family };