import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { theme } from '../../EmraxisTheme/Theme';
import GenerateInvoice from './GenerateInvoice';
import store from '../../API/store';
import DataDecode from '../../utils/DataDecode';
import DateFunction from '../../utils/DateFunctions';
import { setAppointmentInvoicePrint } from '../../Redux/features/popup/popupSlice';
import { setAppointmentInvoice,setShowAppEditInvoice } from '../../Redux/features/invoice/invoiceSlice';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import Select from "react-select";

const AdminInvoices = () => {
    const [sortType, setSortType] = useState("all");
    const [sortedAlInvoices, setSortedAllInvoices] = useState([]);
    const [allInvoices, setAllInvoices] = useState([]);
    const [search, setSearch] = useState("");
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [createInvoice, setCreateinvoice] = useState(false);
    const [AppDropdown, setAppDropdown] = useState(false);
    const [patDropdown, setPatDropdown] = useState(false);
    const [appData, setAppData] = useState(null);
    const [appointmentOptions, setAppointmentOptions] = useState([]);
    const [uniquetOptions, setUniqueOptions] = useState([]);
    const [selectedPatientOpNumber, setSelectedPatientOpNumber] = useState("");
    const [selectedAppointmentId, setSelectedAppointmentId] = useState("");
    const [filteredData, setFilteredData] = useState(null);
    const [refreshData, setRefreshData] = useState(false);
    const [errors, setErrors] = useState({
      patient: "",
      appointmentId: "",
    });
    const [page, setPage] = useState("Admin Invoice");
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var showAppointmentInvoicePrint = useSelector(state => state.popup.showAppointmentInvoicePrint);
    const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
    const dispatch = useDispatch()

    const validateForm = () => {
      let valid = true;
      const newErrors = {
        patient: "",
        appointmentId: "",
      };
  
      if (!selectedPatientOpNumber) {
        newErrors.patient = "Select  Patient Name/OP ";
        valid = false;
      } else if (!selectedAppointmentId) {
        newErrors.appointmentId = "Select Appointment ";
        valid = false;
      }
      setErrors(newErrors);
      return valid;
    };
  
    const handlePreview = (invoiceDetails, type) => {
      if (!validateForm()) {
        dispatch(
          setNotification({
            message: "* Fields are required!",
            status: "error",
            action: true,
          })
        );
        return;
      }
      setPage("create Invoice");
      dispatch(setShowAppEditInvoice(true))     
      dispatch(setAppointmentInvoicePrint(true));
      dispatch(
        setAppointmentInvoice({
          patient: invoiceDetails?.patients,
          doctor: invoiceDetails?.appointments?.doctor_id,
          appointment: invoiceDetails?.appointments,
          targetFrom: "create Invoice",
        })
      );
      OnClose();
    };
  
    let payloads = DataDecode.encryptPayload({
      clinic_guid: loggeduser.facilityID,
      currentDate: DateFunction.SetDbCurrentTimeStamp(),
      page: 1,
      sort_by: sortType,
    });
    const getAllAppointments = () => {
      store
        .getAppointments(loggedusertoken, payloads)
        .then((result) => {
          if (result.status == 200) {
            result.json().then((res) => {
              let response = DataDecode.decryptResponse(res);
              var myObject = JSON.parse(response);
              let appointmentData = myObject?.appointmentData;
              setAppData(appointmentData);
              const uniqueTransformedData = Array.from(
                new Map(
                  appointmentData.map((item) => [
                    item.patient_op_number,
                    {
                      key: item.appointment_id,
                      value: `${item.FirstName + " " + (item.MiddleName?.charAt(0) || "") + " " +
                        item.LastName + " - " + item.patient_op_number}`,
                    },
                  ])
                ).values()
              ).sort((a, b) => a.value.localeCompare(b.value));              
              setUniqueOptions(uniqueTransformedData);
            });
          } else if (result.status == 404) {
            setAppData([]);
          }
        })
        .catch((err) => {
          dispatch(
            setNotification({
              message: "Error Occurred!",
              status: "error",
              action: true,
            })
          );
        });
    };
  
    const groupByPatientOpNumber = (appointments) => {
      return appointments?.reduce((result, item) => {
        const { patient_op_number, appointment_time, app_time } = item;
        if (!result[patient_op_number]) {
          result[patient_op_number] = [];
        }
        result[patient_op_number].push(
          `${DateFunction.GetDate(
            appointment_time
          )} - ${DateFunction.Convert12Hour(app_time)}`
        );
        return result;
      }, {});
    };
  
    const extractPatientOpNumber = (selectedValue) => {
      return selectedValue?.split(" - ")[1];
    };
  
    const getAppointmentsForPatient = (patientOpNumber, groupedData) => {
      return groupedData[patientOpNumber] || [];
    };
  
    const groupedAppointments = groupByPatientOpNumber(appData);
  
    // Handle dropdown selection
  
    const handlePatientChange = (selectedValue) => {
      setSelectedPatientOpNumber(selectedValue);
      const patientOpNumber = extractPatientOpNumber(selectedValue);
      const appointmentIds = getAppointmentsForPatient(
        patientOpNumber,
        groupedAppointments
      );
      setAppointmentOptions(appointmentIds);
      setSelectedAppointmentId("");
      setFilteredData(null);
    };
  
    const handleAppointmentChange = (selectedValue) => {
      setSelectedAppointmentId(selectedValue);
      const result = appData.find(
        (item) =>
          item.patient_op_number ===
          extractPatientOpNumber(selectedPatientOpNumber) &&
          `${DateFunction.GetDate(
            item.appointment_time
          )} - ${DateFunction.Convert12Hour(item.app_time)}` === selectedValue
      );
      if (result) {
        const jsonResult = {
          appointments: result,
          clinic_guid: loggeduser.facilityID,
          patients: result,
        };
        setFilteredData(jsonResult);
      }
    };

    const GetAllInvoice = () => {
        store.GetAllInvoice(loggedusertoken, DataDecode.encryptPayload({ clinic_guid: loggeduser.facilityID, sort_by: sortType }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        var data = JSON.parse(response);
                        setAllInvoices(data);
                        setSortedAllInvoices(data);
                    })
                } else {
                    setAllInvoices([])
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }

 useEffect(() => {
    if (loggeduser) {
      GetAllInvoice();
      getAllAppointments();
    }
  }, [loggeduser, sortType, refreshData]);   

    useEffect(() => {
        if (allInvoices.length > 0 && search.trim() !== "") {
            let invoice = allInvoices.filter(a => a.patients.patient_name?.toLowerCase()?.includes(search.toLowerCase())
                || a.patients.mobile_number?.toLowerCase()?.includes(search.toLowerCase())
                || a.doctorName?.toLowerCase()?.includes(search.toLowerCase())
                || a.invoice_code?.toLowerCase()?.includes(search.toLowerCase())
            )
            setSortedAllInvoices(invoice);
        }
    }, [search, allInvoices, sortType])

    const printInvoice = (inv) => {
    setPage("Admin Invoice");
      dispatch(setAppointmentInvoicePrint(true))
      dispatch(setAppointmentInvoice({ patient: inv.patients, doctor: inv.appointments?.doctor_id, appointment: inv.appointments, targetFrom: inv.targetFrom }))
    }

    const OnClose = () => {
      setAppDropdown(false);
      setPatDropdown(false);
      setCreateinvoice(false);
      setSelectedPatientOpNumber("");
      setSelectedAppointmentId("");
      setErrors({
        ...errors,
        patient: "",
        appointmentId: "",
      });
    };
    const customStyles = {
      control: (base) => ({
        ...base,
        height: 40,
        borderColor: errors.patient ? "red" : "gray",
        boxShadow: "none",
      }),
      menu: (base) => ({
        ...base,
        maxHeight: "150px",
        overflowY: "auto",
      }),
      menuList: (base) => ({
        ...base,
        maxHeight: "150px",
        overflowY: "auto",
        padding: 0,
      }),
  
      placeholder: (base) => ({
        ...base,
        color: "gray",
      }),
      singleValue: (base) => ({
        ...base,
        color: "black",
      }),
      input: (provided) => ({
        ...provided,
        margin: "0px",
        "input:focus": {
          boxShadow: "none",
        },
      }),
      indicatorSeparator: () => ({
        display: "none",
      }),
    };
  
    let AddInvoice = (
      <div
        id="FeedBackDivfeedbackFormContainer"
        className="fixed inset-0  flex justify-center items-center z-50"
      >
        <div
          id="FeedBackDivoverlay"
          className="absolute inset-0 bg-black opacity-25"
        ></div>
  
        <div
          id="FeedBackDivfeedbackForm"
          className={`relative bg-white rounded-lg shadow-lg p-6 w-full max-w-lg ${patDropdown ? "h-[350px]" : AppDropdown ? "h-[410px]" : "h-auto"
            }`}
          style={{ width: "400px" }}
        >
          <div
            id="FeedBackDivfeedbackHeader"
            className="flex justify-between items-center mb-6"
          >
            <div
              id="FeedBackDivfeedBackFormTitle"
              className="text-black font-bold text-[25px]"
            >
              Create Invoice
            </div>
            <button
              id="FeedBackBtncloseFeedbackForm"
              className="text-red-700"
              onClick={() => OnClose()}
            >
              <svg
                id="FeedBackSvgcloseFeedbackFormIcon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-10 w-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </button>
          </div>
  
          <div
            id="FeedBackDivfeedbackContent"
            className="grid lg:grid-cols-1 xs:grid-cols-1 lg:gap-4 xs:gap-1"
          >
            <div className="mb-4">
              <label className="block text-sm font-medium">
                Patient Name/OP <span className="text-red-500">*</span>
              </label>
  
              <Select
                placeholder="Select Patient"
                options={uniquetOptions.map((option) => ({
                  value: option.value,
                  label: option.value,
                }))}
                styles={customStyles}
                onMenuOpen={() => setPatDropdown(true)}
                onChange={(selectedOption) => {
                  handlePatientChange(selectedOption.value);
                  setErrors({ ...errors, patient: "" });
                  setPatDropdown(true);
                }}
                onMenuClose={() => setPatDropdown(false)}
              />
              {errors?.patient && (
                <p className="text-red-500 text-sm mt-1">{errors.patient}</p>
              )}
            </div>
  
            {selectedPatientOpNumber && (
              <div className="mb-4">
                <label className="block text-sm font-medium">
                  Appointment <span className="text-red-500">*</span>
                </label>
                <Select
                  placeholder="Select Appointment"
                  options={appointmentOptions.map((option) => ({
                    value: option,
                    label: option,
                  }))}
                  styles={customStyles}
                  onChange={(selectedOption) =>
                    handleAppointmentChange(selectedOption.label)
                  }
                  value={
                    selectedAppointmentId
                      ? { value: selectedAppointmentId, label: selectedAppointmentId }
                      : null
                  }
                  onMenuOpen={() => setAppDropdown(true)}
                  onMenuClose={() => setAppDropdown(false)}
                />
                {errors?.appointmentId && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.appointmentId}
                  </p>
                )}
              </div>
            )}
  
            <div id="FeedBackDivfeedbackSubmit" className="flex justify-center">
              <button
                id="FeedBackBtnsubmitFeedback"
                className="cursor-pointer mt-2  w-[25%] justify-center bg-red-800 hover:bg-red-800 text-white px-2 py-2 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={() => handlePreview(filteredData)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  
    return (         
        <div className=" mx-auto py-2 my-20 w-100% ">
            <div className=' flex flex-row gap-8 justify-between mb-5'>
                <div className="flex items-center  ">
                    <header id="AdminInvoicesHeadertitle" className={`origin-top-left flex font-bold mb-2 text-2xl lg:text-2xl sm:text-lg pl-3 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Invoices</header>
                </div>
                <div className='flex'>
                    <select id="AdminInvoicesSelectdropDown" value={sortType} className='block border text-sm rounded-md focus:outline-none focus:border-gray-500 px-2 py-2 cursor-pointer mx-4' onChange={e => setSortType(e.target.value)}>
                        <option id="AdminInvoicesOptionall" value="all">All</option>
                        <option id="AdminInvoicesOptiontoday" value="today">Today</option>
                        <option id="AdminInvoicesOptionweek" value="week">This Week</option>
                        <option id="AdminInvoicesOptionmonth" value="month">This Month</option>
                    </select>                    
                    <div className="hidden relative items-center gap-2 mx-4 md:block">
                        <input type="text" value={search} id="AdminInvoicesInputsearch" className="block pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none" placeholder="Id, Patient, Doctor Name"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <svg id="AdminInvoicesInputsearchIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 absolute left-3 top-1/2 transform -translate-y-1/2 text-red-700">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                    </div>
                    <div id="generateInvoiceContainer" className="">
                      <button
                        id="generateInvoiceButton"
                        onClick={() => setCreateinvoice(true)}
                        className={`py-2 truncate px-4 rounded-md flex text-sm items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor}`}
                        style={{ fontFamily: previewTheme.fontFamily }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6 mr-2"
                        >
                          <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                        </svg>
                        Generate Invoice
                      </button>
                    </div>
                    <button id="AdminInvoicesBtnreload" className="items-center lg:ml-6 mx-4" onClick={() => window.location.reload()}>
                        <svg id="AdminInvoicesBtnreloadIcon" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-reload  h-8 w-8 mr-2 text-red-700 hover:text-red-800" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
                            <path d="M20 4v5h-5" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className='cursor-default'>
                <div className='block lg:hidden'>
                    <table className="table-auto w-full border focus:outline-none min-w-full bg-white shadow-lg rounded-lg">
                        <thead id="AdminInvoicesTableheader" className={`text-sm`}>
                            <tr className='px-2 py-4 text-left border-b'>                                
                                <th id="AdminInvoicesThinvoicedate" className="px-2 py-2 text-left">
                                    Invoice Date
                                </th>
                                <th id="AdminInvoicesThpatientname" className="px-2 py-2 text-left">
                                    Patient Name
                                </th>                                
                                <th id="AdminInvoicesThtotal" className="px-2 py-2 text-right ">
                                    Total
                                </th>
                                <th className="px-2 py-2 text-center ">Print</th>
                            </tr>
                        </thead>
                        <tbody id="AdminInvoicesTbodyinvoice" className='text-sm'>
                            {
                                sortedAlInvoices.map((inv, ind) => (
                                    <tr key={'app-table-' + ind} className='odd:bg-white even:bg-red-100 transition duration-200'>
                                        
                                        <td id="AdminInvoicesTdinvoiceDate" className="px-2 py-2 text-left">
                                            {DateFunction.GetDate(inv.invoice_on)}
                                        </td>

                                        <td id="AdminInvoicesTdpatientName" className="px-2 py-2 text-left">
                                            {inv.patients?.FirstName + " " + inv.patients?.MiddleName?.charAt(0) + " " + inv.patients?.LastName}
                                        </td>
                                        
                                        <td id="AdminInvoicesTdtotalAmount" className="px-2 py-2 text-right">
                                            {inv.totalAmount}
                                        </td>
                                        <td id="AdminInvoicesTdbtnContainer" className="text-center">
                                            <button id="AdminInvoicesBtninvoicePrint"
                                            onClick={() => {
                                            printInvoice(inv);
                                            setSelectedInvoice(inv);
                                            }}
                                               className={`disabled:cursor-not-allowed text-blue-600 disabled:text-blue-300`}>
                                                <svg id="AdminInvoicesSvginvoicePrintIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5">
                                                    <path fillRule="evenodd" d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 0 0 3 3h.27l-.155 1.705A1.875 1.875 0 0 0 7.232 22.5h9.536a1.875 1.875 0 0 0 1.867-2.045l-.155-1.705h.27a3 3 0 0 0 3-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0 0 18 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM16.5 6.205v-2.83A.375.375 0 0 0 16.125 3h-8.25a.375.375 0 0 0-.375.375v2.83a49.353 49.353 0 0 1 9 0Zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 0 1-.374.409H7.232a.375.375 0 0 1-.374-.409l.526-5.784a.373.373 0 0 1 .333-.337 41.741 41.741 0 0 1 8.566 0Zm.967-3.97a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H18a.75.75 0 0 1-.75-.75V10.5ZM15 9.75a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V10.5a.75.75 0 0 0-.75-.75H15Z" clipRule="evenodd" />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            {
                                sortedAlInvoices.length > 0 &&
                                <tr className='border-t border-gray-300'>
                                    <td id="AdminInvoicesTdtotalAmount" colSpan={4} className='text-right p-2 text-green-600 font-semibold text-lg'><span className='text-black'>Total Amount:</span> {sortedAlInvoices.reduce((sum, item) => sum + (item.totalAmount), 0)}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className='hidden lg:block'>
                    <table className="table-auto w-full border focus:outline-none min-w-full bg-white shadow-lg rounded-lg">
                        <thead id="AdminInvoicesTableheader" className={`text-sm ${previewTheme.tableHeaderText} ${previewTheme.tableHeaderBg} h-12`}>
                            <tr className='px-2 py-4 text-left border-b'>
                                <th id="AdminInvoicesThinvoiceId" className="px-2 py-2 text-left">
                                    Invoice ID
                                </th>
                                <th id="AdminInvoicesThinvoicedate" className="px-2 py-2 text-left">
                                    Invoice Date
                                </th>
                                <th id="AdminInvoicesThpatientname" className="px-2 py-2 text-left">
                                    Patient Name
                                </th>
                                <th id="AdminInvoicesThdoctorname" className="px-2 py-2 text-left">
                                    Doctor Name
                                </th>
                                <th id="AdminInvoicesThtotal" className="px-2 py-2 text-right ">
                                    Total
                                </th>
                                <th className="px-2 py-2 text-center ">Print</th>
                            </tr>
                        </thead>
                        <tbody id="AdminInvoicesTbodyinvoice" className='text-sm'>
                            {
                                sortedAlInvoices.map((inv, ind) => (
                                    <tr key={'app-table-' + ind} className='odd:bg-white even:bg-red-100 transition duration-200'>
                                        <td id="AdminInvoicesTdinvoiceId" className="px-2 py-2 text-left">
                                            {inv.invoice_code}
                                        </td>
                                        <td id="AdminInvoicesTdinvoiceDate" className="px-2 py-2 text-left">
                                            {DateFunction.GetDate(inv.invoice_on)}
                                        </td>

                                        <td id="AdminInvoicesTdpatientName" className="px-2 py-2 text-left">
                                            {inv.patients?.FirstName + " " + inv.patients?.MiddleName?.charAt(0) + " " + inv.patients?.LastName}
                                        </td>
                                        <td id="AdminInvoicesTddoctorName" className="px-2 py-2 text-left">
                                            {inv.doctorName}
                                        </td>
                                        <td id="AdminInvoicesTdtotalAmount" className="px-2 py-2 text-right">
                                            {inv.totalAmount}
                                        </td>
                                        <td id="AdminInvoicesTdbtnContainer" className="text-center">
                                            <button id="AdminInvoicesBtninvoicePrint"
                                             onClick={() => {
                                            printInvoice(inv);
                                            setSelectedInvoice(inv);
                                            }}
                                               className={`disabled:cursor-not-allowed text-blue-600 disabled:text-blue-300`}>
                                                <svg id="AdminInvoicesSvginvoicePrintIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5">
                                                    <path fillRule="evenodd" d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 0 0 3 3h.27l-.155 1.705A1.875 1.875 0 0 0 7.232 22.5h9.536a1.875 1.875 0 0 0 1.867-2.045l-.155-1.705h.27a3 3 0 0 0 3-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0 0 18 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM16.5 6.205v-2.83A.375.375 0 0 0 16.125 3h-8.25a.375.375 0 0 0-.375.375v2.83a49.353 49.353 0 0 1 9 0Zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 0 1-.374.409H7.232a.375.375 0 0 1-.374-.409l.526-5.784a.373.373 0 0 1 .333-.337 41.741 41.741 0 0 1 8.566 0Zm.967-3.97a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H18a.75.75 0 0 1-.75-.75V10.5ZM15 9.75a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V10.5a.75.75 0 0 0-.75-.75H15Z" clipRule="evenodd" />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            {
                                sortedAlInvoices.length > 0 &&
                                <tr className='border-t border-gray-300'>
                                    <td id="AdminInvoicesTdtotalAmount" colSpan={6} className='text-right p-2 text-green-600 font-semibold text-lg'><span className='text-black'>Total Amount:</span> {sortedAlInvoices.reduce((sum, item) => sum + (item.totalAmount), 0)}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>

            </div>
            {createInvoice && AddInvoice}     
            {showAppointmentInvoicePrint && <GenerateInvoice sortedAlInvoices={sortedAlInvoices} selectedInvoice={selectedInvoice} page={page} setRefreshData={setRefreshData}/>}
        </div>
    )
}

export default AdminInvoices