import React, { useState, useEffect } from 'react';
import demoBtn from '../Assets/homeImages/demoButton1.png';
import demoBtnMobile from '../Assets/homeImages/demoButton2.png';

const RequestFloatingButton = ({ scrollToDiv }) => {
  const [isScrolling, setIsScrolling] = useState(false);
  let scrollTimeout;

  useEffect(() => {
    const handleScroll = () => {
      clearTimeout(scrollTimeout);
      setIsScrolling(true);

      scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 200); // Adjust the timeout duration as needed
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div id="RequestButtonDivparent">
      <div id="RequestButtonDivchild" className="block lg:hidden">
        <div id="RequestButtonDivdemoButton" className={`fixed bottom-4 right-4 ${isScrolling ? 'block' : 'hidden'}`}>
          <img id="RequestButtonImgdemoButton" src={demoBtn} alt="button" className='w-[60px]' />
        </div>
        <button id="RequestButtonButtonScroll"
          className={`fixed flex bottom-10 right-4  items-center justify-center text-white text-[16px] font-semibold xl:text-[16px] bg-[#E7714C] p-2 xl:p-4  rounded-lg ${isScrolling ? 'hidden' : 'block'}`}
          onClick={() => { scrollToDiv() }}
        >
          <span className="">Request a demo</span>
        </button>
      </div>
      <div id="RequestButtonDivdemo" className="relative hidden lg:block">
        <div  id="RequestButtonDivdemoChild" className="group">
          <div id="RequestButtonDivdemoButton2" className="div1 group-hover:hidden fixed bottom-4 right-4">
            <img id="RequestButtonImgdemoButton2" src={demoBtn} alt="button" className='w-[75px]'/>
          </div>
          <button id="RequestButtonButtonscrollToDiv"
            className="fixed div2 hidden group-hover:flex bottom-10 right-4  items-center justify-center text-white text-[13px] font-semibold xl:text-[16px] bg-[#E7714C] p-2 xl:p-4  rounded-lg"
            onClick={() => { scrollToDiv() }}
          >
            <span id="RequestButtonSpanrequestdemo" className="">Request a demo</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestFloatingButton;