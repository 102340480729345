import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { setNotification } from '../Redux/features/toast/toastSlice';

const ToastMessage = () => {
    const dispatch = useDispatch();
    const notification = useSelector((state) => state.toast.notification);
  
    if (!notification) return null;
  
    const handleClose = () => {
      dispatch(setNotification({
        action: false,
        message: null,
        status: 'success',
        autoHideDuration: 5000
      }));
    };
  
    return (
      <Snackbar
        open={notification.action}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          width: 'auto',
          maxWidth: '400px',
        }}
      >
        <Alert 
          onClose={handleClose} 
          severity={notification.status} 
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    );
  };

export default ToastMessage;