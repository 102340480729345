import DateFunction from "../utils/DateFunctions";
export const generateHtmlStringForPDF = (sectionsData, patientName, patientId, version) => {
    const sectionsHtml =  sectionsData.map(section => {
        const sectionHeader = section.SectionName;
        const summaryContent = section.Summary.map(summary => summary.SummarizedSegment).join('\n');
  
            return `
            <div class="section">
                <h5 class="section-title">${sectionHeader.replace(/_/g, ' ')}</h5>
                <div class="content">
                    <p>- ${summaryContent}</p>
                </div>
            </div>
            `;
        }).join('');
  
    return `
    <html>
  <head>
    <title>Clinical Notes</title>
    <style>
      body {
        font-family: Arial, sans-serif;
        line-height: 1.6;
        margin: 0;
        padding: 0;
        background-color: #fff;
        color: #333;
      }

      .container {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
      }

      h1 {
        text-align: center;
        color: #004F59;
        font-size: 24px;
        font-weight: bold;
      }

      .section-title {
        margin-top: 20px;
        font-weight: normal;
        color: #004F59;
        font-size: 18px;
      }

      .section-content {
        margin-left: 20px;
        margin-bottom: 20px;
        font-size: 16px;
      }

      .section-content ul {
        padding-left: 20px;
        list-style: none;
        margin-bottom: 20px;
      }

      .section-content ul li::before {
        content: "-";
        margin-right: 10px;
      }

      .section-content ul li {
        margin-bottom: 20px;
      }

      .footer {
        width: 100%;
        border-top: 1px solid black;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.9rem;
    
        left: 0;
        right: 0;
      }

      .footer div {
        display: flex;
        flex-direction: column;
      }

      .footer .left,
      .footer .center,
      .footer .right {
        text-align: left;
      }

      .footer .center {
        text-align: center;
        flex-grow: 1;
      }

      .footer .right {
        text-align: right;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <h1>Clinical Notes</h1>
      ${sectionsHtml}
    </div>
    
  </body>
</html>

    `;
  };